
// Site Status
// ================================

.site-status,
[id="site-status"] {
  text-align: center;
  font-size: @h4-size;
  font-weight: normal;

  a {
    color: @white;
    text-decoration: underline;
    .text {
      border-bottom: 1px dotted @white;
    }
    &:hover {
      text-decoration-style: solid;
      text-decoration: none;
      .text {
        border-bottom: 1px solid @white;
      }
    }
  }
}

.hasEmergencyBanner {
  position: relative;
  -webkit-box-shadow: 0 8px 6px -6px @darkgrey;
  -moz-box-shadow: 0 8px 6px -6px @darkgrey;
  box-shadow: 0 8px 6px -6px @darkgrey;
  &.isClosed {
    display: none;
  }

  .bannerClose {
    .abs(0,0,0,auto);
    border: none;
    border: none;
    display: flex;
    align-content: center;

    .text {
      display: inline-block !important;
      vertical-align: middle;
      width: 20px;
      height: 4px;
      background: @white;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      text-indent: -9999px;
      transition: background-color 150ms ease;

      &:before, &:after {
        display: inline-block;
        width: 100%;
        height: 4px;
        background: @white;
        position: absolute;
        left: 0;
        content: '';
        transform-origin: center;
        transition: transform 150ms ease, top 150ms ease, color 150ms ease;
      }
      &:before { top: 8px; }
      &:after { top: -8px; }
    }
    .X-STYLE() {
      border-bottom-color: transparent;
      .text {
        background-color: transparent;
        box-shadow: none;
        &:before,
        &:after {
          opacity: 1;
          box-shadow: none;
          height: 4px;
        }
        &:before {
          top: 0;
          transform: rotate(-45deg);
        }
        &:after {
          top: 0;
          transform: rotate(45deg);
        }
      }
    }

    .X-STYLE();
  }
}
