.search-results {
  .unbullet();

  .title {
    .h4;
    font-weight: bold;
    a {
      text-decoration: none;
    }
  }

  .search-snippet-info {
    padding: 0;
    font-size: @caption-font-size;
  }
  .search-info {
    margin-top: 1em;
  }

  .search-result {
    padding: 26px 0;
    margin: 0;
    ~ .search-result {
      border-top: 1px solid @hr-color;
    }
  }
}

// Google
.gsc-table-result, .gsc-thumbnail-inside, .gsc-url-top {
  padding-left: 0 !important;
  padding-right: 0 !importantˇ;
}