
// Media Queries
// ================================

@mobilemax: 480px;
@baby_bear: 580px;
@goldilocks: 700px;
@tabletmax: 960px;
@mama_bear: 1100px;
@berenstain: 1200px;
@grizzly: 1400px;



// Responsive
// ================================

.resp(@maxWidth; @rules) {
  @media only screen and (max-width: @maxWidth) {
    @rules();
  }
}
.resp-min(@minWidth; @rules) {
  @media only screen and (min-width: @minWidth) {
    @rules();
  }
}
.resp-range(@minWidth; @maxWidth; @rules) {
  @media only screen and (min-width: @minWidth) and (max-width: @maxWidth) {
    @rules();
  }
}

@media only screen and (min-width: 768px){
.grid.col-2 .grid-container {
    font-size: 0;
    margin: -20px;
    display: flex;
  }
}





// JS Breaks
// ================================

.js-breaks__ {
  &mobilemax { width: @mobilemax; }
  &baby_bear { width: @baby_bear; }
  &goldilocks { width: @goldilocks; }
  &tabletmax { width: @tabletmax; }
  &mama_bear { width: @mama_bear; }
  &berenstain { width: @berenstain; }
  &grizzly { width: @grizzly; }
}
