/// Toggles

.mobileMainMenuToggle {
  .resp-range(@mobilemax + 1, @tabletmax, {
    #ma-toggle > .hamburger-menu(@with-x: true);
  });

  .resp(@mobilemax, {
    #ma-toggle > .hamburger-menu(@with-x: false);
  });

  .resp-min(@mobilemax + 1, {
    .text { width: 24px; }
  });
}

.toggleSwitch {

  padding: 10px 14px;
  width: 100px;
  text-align: center;
  display: inline-block;
  &.isOpen {
    background-color: @brand-primary;
    .mobileMainMenuToggle& {
      .resp(@mobilemax, {
        background-color: transparent;
      });
    }
  }


  .resp(@baby_bear, {
    padding: 10px 14px;
    width: 52px;
    text-align: center;
    display: inline-block;
    &.isOpen:not(.mobileMainMenuToggle) { background-color: transparent; }
  });
}

.mainSearchToggle {
  display: none;

  .resp(@mobilemax, {
    display: inline-block;
    #ma-toggle > .search-menu();
  });

  &Proxy {
    display: block;
    .resp(@tabletmax, {
      display: none;
    });
  }
}


.togglePane.hasToggle {
  &.mainMenuPane {
    .resp(@tabletmax, {
      #ma-toggle > .pane();
      position: absolute;
      z-index: 40;
    });
  }

  &.tlAccordion {
    .resp(@tabletmax, {
      #ma-toggle > .pane();
    });
  }

  &.mainSearchPane {
    .resp(@tabletmax, {
      height: auto;
      transform: none;
      opacity: 1;
    });
    .resp(@mobilemax, {
      #ma-toggle > .pane();
    });
  }

  &.tabAccordion {
    // See: /
    .resp(@baby_bear, {
      #ma-toggle > .pane();
    });
  }
}

.hasAccordionSwitch {
  #ma-toggle > .accordionSwitch();
}
.accordionPane.hasToggle {
  #ma-toggle > .pane();
  padding: 1.5rem 20px;
  background-color: @faint-bg;
}