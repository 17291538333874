.page-user-login {
  .region-content-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .block.notification {
      margin-bottom: 0;
    }
    #block-system-main,
    #block-block-6 {
      flex-basis: ~"calc(50% - 1.5em)";
      @media (max-width: 880px) {
        flex-basis: 100%;
      }
    }
  }
}