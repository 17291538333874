#block-google-translator-active-languages {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0;
  padding-left: 2rem;
  > * {
    flex: 1 0 auto;
  }

  #block-menu-menu-utility #google_translator_element a,
  #block-menu-menu-utility #google_translator_element span,
  #google_translator_element div {
    display: inline-block;
  }

  img {
    display: none;
  }

  .notranslate {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .goog-te-gadget-simple {
    background-color: rgba(255, 255, 255, 0.9);
    border: 0;
    color: #555;
    font-family: 'Source Sans Pro', 'Helvetica Neue', 'Arial', 'Helvetica', 'Verdana', sans-serif;
    font-size: .7rem;
    font-weight: 600;
    height: 40px;
    letter-spacing: .15em;
    margin-bottom: 4px;
    padding: 10px;
    padding-right: 0;
    text-transform: uppercase;

    .goog-te-menu-value {
      &:hover,
      &:focus {
        text-decoration: none;
      }

      span {
        padding-right: .5rem;

        &[aria-hidden=true] {
          display: inline-block;
          padding-right: 0;
        }
      }
    }
  }
}

.resp-range(@mobilemax + 1, @tabletmax, {
  #block-google-translator-active-languages {
    margin: 1rem 0;
    text-align: center;
    padding-left: 0;
  }
});
.resp(@mobilemax, {
  #block-google-translator-active-languages {
    margin: 1rem 0;
    text-align: center;
    padding-left: 0;
  }
});
