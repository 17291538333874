
.search-field(@textfield: ~'.form-type-textfield'; @submit: ~'.form-submit') {
  position: relative;

  @{textfield} {
    margin: 0;
    input {
      padding-right: 40px;
    }
  }
  @{submit} {
    .abs(0,0,0,auto);
    margin: 0;
    background-color: transparent;
    background-image: @search;
    background-repeat: no-repeat;
    background-position: 50% 46%;
    background-size: 20px 20px;
    color: transparent;
    text-shadow: none;
    box-shadow: none;
    width: 50px;
    height: 40px;
    border: none;

    &:hover {
      transform: none;
      background-image: @search--teal;
    }
  }
}

// See: /research/databases
.region[class*="sidebar"] .block-search-catalog form > div {
  .search-field();
}