
// Tabs
.tabs-custom {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .button-tray {
    margin: 0;
    padding: 1.35rem 2.2rem 1.7rem; // 10px 22px
    background-color: @contrast-bg;
    .button {
      .button-cta();
      display: block;
      text-align: center;
      padding-top: 0.5rem;
      letter-spacing: 0.05rem;
    }
  }

  > .button-tray {
    display: none;
  }

  .resp(@baby_bear, {
    display: block; // For android
    > .button-tray {
      display: block;
    }
  });
}

.tab-bar {
  flex: 1 0 280px;
  flex-direction: column;
  background: @contrast-bg;
  padding: 1.675rem 0; // 26.8px 0

  .resp(@tabletmax, {
    flex: 1 0 38.5%;
  });

  .resp(@baby_bear, {
    display: none;
    width: 100%;
    flex: 1 0 100%;
  });

  .tabs-custom & {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;

    .resp(@baby_bear, {
      display: none;
      width: 100%;
      flex: 1 0 100%;
    });

    > * {
      width: 100%;
    }
    .button-tray {
      // margin-bottom: 0;
      // margin-top: auto;
      // // The margins above allows tray to stick to bottom
      // // Removed for JLL-721
      padding: 0.625rem 1.375rem 2.7rem; // 10px 22px
    }
  }
}

.tab-switch {
  .clearly;
  display: block;
  border-left: 6px solid transparent;
  padding: 0.625rem 1.375rem .8125rem; // 10px 22px 13px

  * {
    .h4();
    color: @white;
    .font-smoothing();
    margin: 0;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus, &.selected { outline: none; }

  &.selected {
    background-color: @white;
    color: @brand-primary;
    border-color: @brand-primary;
    &, &:hover { cursor: default; }

    * {
      .font-smoothing(subpixel-antialiased);
      color: inherit;
    }
  }
}


.tabs-inner {
  background-color: @white;
  padding: 1.875rem 3.125rem ; // 30px 50px;
  flex-basis: 100%;

  .resp(@tabletmax, {
    //flex: 1 0 60%;
    padding: 2.175rem 2.5rem 1.875rem;
  });

  .resp(@baby_bear, {
    width: 100%;
    padding: 0;
    display: block; // for android
  });

  .tab-title {
    display: none;
    &:hover {
      text-decoration: none;
    }

    > * {
      margin: 0;
      color: inherit;
    }
    .resp(@baby_bear, {
      display: block;
      color: @white;
      background: @contrast-bg;
      position: relative;

      h3 {
        .h4();
        color: @white;
        padding: .75rem;
      }

      .toggleSwitch {
        .abs-0();
        width: auto;

        .text {
          position: relative;
          text-indent: -9999px;
          display: block;
          height: 100%;
          &:after {
            content: ' ';
            .abs(50%,0,auto,auto);
            height: 1em;
            width: 1rem;
            background-image: @dropdown-arrow--white;
            background-position: right bottom;
            background-repeat: no-repeat;
            background-size: 12px 12px;
            .t-y(-50%);
            .t-scale3d(~"1,1,1");
            .trans(transform);
          }
        }

        &.isOpen .text:after {
          .t-y(-50%);
          .t-scale3d(~"1,-1,1");
        }
      }
    });
  }

  .field-research-link-sets > .field-contents {
    .resp-min(@tabletmax, {
      ~ .field-contents { padding-top: 2.6rem; }
    });
    .resp-range(@mobilemax + 1, @tabletmax {
      ~ .field-contents { padding-top: 2.4rem; }
    });
    .resp(@baby_bear, {
      &:last-child { padding-bottom: 1.75rem; }
    });
  }

  .start-research-link-set {
    // See: /
    color: @medium-text-color;

    .resp(@baby_bear, {
      padding: 1.5rem .65rem .25rem;
    });

    > .ds-first {}

    .reslinksgrid() {
      @items: ~'.field-research-links';
      .makeGrid(@items;  1.5rem, 1.875rem;  2);
      text-align: justify;
      line-height: 0;
      @{items} {
        max-width: 426px;
        text-align: initial;
        line-height: initial;
      }
      &:after {
        content: ' ';
        display: inline-block;
        width: 100%;
      }

      .resp(@tabletmax, {
        margin: 0;
        @{items} {
          display: block;
          width: 100%;
          padding: 0 0 1.85rem;
          max-width: 100%;
          &:last-child {
            padding-bottom: 0;
          }
        }
        &:after { content: none; }
      });
      .resp(@mobilemax, {
        margin: 0;
        @{items} {
          display: block;
          padding: .4rem 0 1.6rem;
          max-width: 100%;
          &:last-child {
            padding-bottom: 0;
          }
        }
        &:after { content: none; }
      });
    }

    > .ds-second {
      .reslinkscols() {
        @items: ~'.field-research-links > .field-contents';
        .CSSColumns(2, 60px, @items, {
          padding-bottom: 1.5rem;
        });
        .resp(@mama_bear, {
          .CSSColumns(1, 0, @items, {
            padding: 0 0 1.85rem;
            &:last-child {
              padding-bottom: 0;
            }
          });
        });
        .resp(@mobilemax, {
          .CSSColumns(1, 0, @items, {
            padding: .4rem 0 1.6rem;
            max-width: 100%;
            &:last-child {
              padding-bottom: 0;
            }
          });
        });
      }
      .reslinkscols();
    }

    > .ds-third {
      margin-top: 1rem;
      .resp(@tabletmax, {
        margin-top: 1.5rem;
      });
      .resp(@mobilemax, {
        margin: 2rem 0 .5rem;
      });
    }

    .field-link {
      line-height: 1.35;
      margin-bottom: .25rem;
      a { display: block; }
    }
    .field-research-more-link {
      a {
        display: block;
        color: @medium-text-color;
        font-weight: 600;
      }
    }
  }


}
.tab-pane {
  display: none;
  &.active {
    display: block;
  }
  .resp(@baby_bear, {
    &, &.active { display: block; }
  });
}

.tabs-custom {
  .tabs-inner .field-research-link-sets .field-contents:last-child .start-research-link-set > .ds-second > .field-research-links > .field-contents {
    display: inline;

    .field.field-link-description {
    padding-bottom: 1.5rem;
}
    &:first-child {
      display: inline-block;
padding-bottom: 0px;
    }

}
}
