
// Node Type Webform
// ================================

.node-type-webform {

	.region-sidebar-second {
    .region-inner {
      .white-sidebar-blocks();
      color: @medium-text-color;
      .aside-list(~".views-row");
      // AJC : Ticket #: JLL-713
    }
    // .region-to-block,
    // .block {
    //   .card-style-post();
    // }
    // AJC : Ticket #: JLL-713
	}
}