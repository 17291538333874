// Inits
// ================================

/* --- [ Variables and Mixins ] --------------- */
@import "_fonts";
@import "_MQs";
@import "_colors";
@import "_icons";
@import "_utils";
@import "_keyframes";

@import "_ma_th";


// Base Styling
// ================================

/* --- [ Normalize ] --------------- */
@import (inline) "../lib/normalize.css";

/* --- [ Normalize ] --------------- */
@import (inline) "../lib/jquery.scrollbar.css";

/* --- [ Base Styles ] --------------- */
@import "partials/_base";
@import "partials/tables";


@import "partials/color-bands";


/* --- [ Behaviors ] --------------- */
@import "partials/behaviors/toggles";
@import "partials/behaviors/overlay-effects";


/* --- [ Forms ] --------------- */
@import "partials/forms";
@import "partials/components/search-field";
@import "partials/views-filters";
@import "partials/chosen";
@import "partials/components/dropdown-filters";
@import "partials/components/date-picker";

@import "partials/components/donate";
@import "partials/components/node-type-webform";
@import "partials/components/membership";
@import "partials/components/request";
@import "partials/components/databases";
@import "partials/components/user";
@import "partials/components/login";
@import "partials/components/cle";
@import "partials/components/checkout";

@import "partials/components/commerce";


@import "partials/components/masquerade";


// Layout
// ================================

.region-inner,
.zone-content {
  margin: 0 auto;
  max-width: 100%;
  max-width: 1400px;
  //.front & { max-width: 1400px; }
}

.region-content-inner {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column; // possibly meant to position pager at bottom
  max-width: 100%;

  flex-direction: row;

  > * {
    width: 100%;
    max-width: 100%; // for IE
  }

  .block-guide-pager,
  .block[id*="guide-pager"] {
    align-self: flex-end;
    width: 100%;
  }
}

// Mixin for horizonatal padding
@side-space: 4.375rem; // 70px
@tablet-side-space: 1.625rem; // 26px
@mobile-side-space: 1.25rem; // 20px

.side-space(@sides: @side-space; @rest...) {
  & when (length(@arguments) = 1) and (length(@sides) = 1) {
    padding-left: @sides;
    padding-right: @sides;
  }
  & when (length(@arguments) = 1) and (length(@sides) > 1) {
    padding-left: extract(@sides, 1);
    padding-right: extract(@sides, 2);
  }
  & when (length(@arguments) > 1) and (length(@sides) > 1) {
    padding-left: extract(@sides, 1);
    padding-right: extract(@sides, 2);
  }
  & when (length(@arguments) > 1) and (length(@sides) = 1) {
    padding-left: @sides;
    padding-right: @rest;
  }
}

// Mixin for vertical padding
@head-space: 3rem, 3.25rem; // 48px 52px;
@tablet-head-space: 3.125rem, 3.75rem; // 26px
@mobile-head-space: 2.25rem; // 36px

.head-space(@ends: @head-space; @rest...) {
  & when (length(@arguments) = 1) and (length(@ends) = 1) {
    padding-top: @ends;
    padding-bottom: @ends;
  }
  & when (length(@arguments) = 1) and (length(@ends) > 1) {
    padding-top: extract(@ends, 1);
    padding-bottom: extract(@ends, 2);
  }
  & when (length(@arguments) > 1) and (length(@ends) > 1) {
    padding-top: extract(@ends, 1);
    padding-bottom: extract(@ends, 2);
  }
  & when (length(@arguments) > 1) and (length(@ends) = 1) {
    padding-top: @ends;
    padding-bottom: @rest;
  }
}

// Give these things default left/right padding
.region-postscript-second-inner,
.region-footer-first {
  .side-space(@side-space);
  .resp(@tabletmax, { .side-space(@tablet-side-space); });
  .resp(@mobilemax, { .side-space(@mobile-side-space); });
}

// Give these things default top/bottom padding
.region-preface-second-inner {
  // See: / (tab area)
  .head-space(@head-space);
  .side-space(4.75rem);
  .resp(@tabletmax, {
    .head-space(1.625rem, 2.125rem);
    .side-space(@tablet-side-space);
  });
  .resp(@mobilemax, {
    .side-space(.5rem); // 8px
    .head-space(1.25rem, 1.35rem);
  });
}

// Give these things double the left right padding
.region-preface-first-inner,
.zone-content, /* special */
.region-user-first-inner /* Emergency Banner */ {
  .side-space(75px);
  .resp(@tabletmax, { .side-space(50px); });
  .resp(@mobilemax, { .side-space(@mobile-side-space); });
  // See: / (Become a Member, Continuing Legal Education)
  .front & {
    .side-space(4.75rem);
    .resp(@tabletmax, { .side-space(3.425rem); });
    .resp(@mobilemax, { .side-space(@mobile-side-space); });
  }
}
// Give these things differing top/bottom padding
.region-preface-first-inner {
  .head-space(3.125rem, 2.95rem);
  .front & {
    .head-space(extract(@head-space, 2), 2.3rem);
    > .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .resp(@tabletmax, {
        justify-content: center;
      });

      > * {
        &:nth-child(odd) {
          flex: 0 0 auto; // for IE
          width: 69.5%; // for IE
          display: flex;
          flex-direction: column;
          padding-right: 2.75rem;

          > * { max-width: 100%; } // for IE

          .resp(@tabletmax, {
            flex-basis: 100%;
            padding-right: 0;
          });

          > .content {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-start;

            > * { max-width: 100%; } // for IE

            > .link-tray {
              display: flex;
              align-items: flex-end;
              padding: .25rem 0.3rem;
              width: 100%;
              flex-grow: 1;
              flex-basis: auto;
              .resp(@tabletmax, {
                justify-content: center;
                padding: 0;
              });

              .resp(@mobilemax, {
                display: block;
                padding: 0;
              });
            }
          }
        }
        &:nth-child(even) {
          flex: 0 0 auto; // for IE
          width: 30.5%; // for IE
          margin-top: -.9375rem;

          .resp(@tabletmax, {
            flex-basis: 20rem;
            margin-top: 3.25rem;
          });

          .resp(@tabletmax, {
            flex-basis: 20.8rem;
            margin-top: 2.25rem;
          });

          .resp(@mobilemax, {
            flex-basis: 100%;
            margin-top: 1.2rem;
          });
        }
      }
    }
  }
  .resp(@tabletmax, { .head-space(2rem, 1.35rem); });
  .front & {
    .resp(@tabletmax, {
      .head-space(2.4rem, 3.05rem);
    });
  }
  .resp(@mobilemax, { .head-space(1.3rem, 1.15rem); });
  .front & {
    .resp(@mobilemax, { .head-space(2.05rem, 2.625rem); });
  }
}

// Emergency Banner
.region-user-first-inner {
  .head-space(.95rem, 1.05rem);
}

.zone-content {
  .head-space(0, 4.525rem);
  .resp(@mobilemax, { .head-space(0, 3.225rem); });
  // See: / (Become a Member, Continuing Legal Education)
  .front & {
    .head-space(3.025rem, 2.625rem);
    .resp(@tabletmax, { .head-space(2.675rem, 3.025rem); });
    .resp(@mobilemax, { .head-space(@mobile-head-space, 2.625rem); });
  }
}

.front {
  .zone-content {
    position: relative;
    &:before {
      content: ' ';
      display: block;
      border-top: 2px solid @hr-color;
      .abs(0, 4.75rem, auto, 4.75rem);
      .resp(@tabletmax, { .abs(0, @tablet-side-space, auto, @tablet-side-space); });
      .resp(@mobilemax, { .abs(0, @mobile-side-space, auto, @mobile-side-space); });
    }
  }
}


.region-postscript-second-inner {
  // See: / (From the Blog)
  .head-space(2.5rem, 3.65rem);
  .resp(@tabletmax, {
    .head-space(2.35rem, 3.7rem);
    .side-space(3.425rem);
  });
  .resp(@mobilemax, {
    .head-space(1.35rem, 3.05rem);
    .side-space(1.2rem);
  });
}

.region-footer-first {
  // See: (Footer: Jenkins Flash)
  .side-space(3.75rem); // 60px
  .head-space(1.125rem, 1.25rem); // 18px 20px

  .resp(@tabletmax, {
    .head-space(1.125rem, 1.475rem); // 21px 30px
  });
  .resp(@mobilemax, {
    .side-space(1.1rem); // 19.2px
    .head-space(1.3rem, 1.75rem); // 20.8px 28px
  });
}

.region-footer-second-inner {
  // See: (Footer)
  .head-space(2.4125rem); // 38.6px
  .side-space(4.75rem);
  .resp(@tabletmax, {
    .side-space(4.75rem); // 76px
    .head-space(1.575rem, 2.0625rem);
  });
  .resp(@mobilemax, {
    .head-space(1.85rem);
    .side-space(1.25rem); // 20px
  });
}



/* --- [ Layout things ] --------------- */
@import "partials/layout/layout-reset";
@import "partials/layout/grids";


@import "partials/layout/search-results";



// Components
// ================================

/* --- [ Aside Items ] --------------- */
@import "partials/components/aside-items";

/* --- [ Share links ] --------------- */
@import "partials/components/share-links";

/* --- [ View Modes ] --------------- */
@import "partials/layout/view-modes";

/* --- [ Site Status ] --------------- */
@import "partials/components/site-status"; // Emergency Banner



// Blocks
// ================================

@import "partials/layout/blocks";


// Views
// ================================

@import "partials/layout/views";



// Standard Pager Styling
@import 'partials/components/pager';

@import "partials/components/progress";

@import "partials/components/flexslider";




@import "partials/header.less";
@import "partials/components/main-nav";
@import "partials/components/block-search-form";
@import "partials/components/logo";

@import "partials/components/tabs.less";

@import "partials/components/back-to-top";

@import "partials/footer.less";
@import "partials/components/homeband.less";
@import "partials/components/translate.less";


// Javascript (global.js)
// ================================

.js_hide {
  display: none !important;
}

.js_no-admin-margin {
  padding-top: 0 !important;
  &:before {
    .js_hide;
  }
}


// Hide me always
// ================================

.hide-me-always {
  display: none !important;
}

.front .feed-icon {
  &:extend(.hide-me-always);
}
