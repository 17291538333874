.desktop-footer() {

  [class*="blockgroup-footer-row"] {
    .region-inner {
      max-width: 100%;
      width: 100%;
    }
  }

  .region-blockgroup-footer-row-1-inner {
    display: table;
    width: 100%;
    > * {
      display: table-cell;
      padding-right: 60px;
      width: auto;
      max-width: 240px;
    }
    > .footer-menu {
      padding-right: 0;
      width: auto;
      max-width: 100%;
    }

    .menu-block-wrapper {
      .menu {
        .unbullet();
        margin: 0;
      }
      > .menu {
        .unbullet();
        margin: 0;
        display: table;
        width: 100%;

        > li {
          display: table-cell;
          width: auto;
          padding-right: 60px;
        }
      }
    }
  }

  .region-blockgroup-footer-row-2-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 5.35rem;

    .footer-social {
      margin-bottom: .45rem;
      .icon {
        margin-right: 2.5rem;
      }
    }
    .footer-legal {
      text-align: right;
    }
  }


  .footer-subscribe {
    .subscribe-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      > h4 {
        flex-basis: 63.4%;
        letter-spacing: normal;
      }
      > .manual-form {
        flex-basis: 36.6%;
        padding-left: 34px;

        #subscribe-form {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          input[type="text"] {
            border-right: none;
          }
        }
      }
    }
  }
}

.tablet-footer() {
  .footer-subscribe {
    .subscribe-wrapper {

      > h4 {
        text-align: center;
        margin-bottom: 1.5rem;
      }
      > .manual-form {

        #subscribe-form {
          max-width: 420px;
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          input[type="text"] {
            border-right: none;
            font-weight: 600;
          }

          input[type="submit"] {
            background-color: @button-color;
            color: @white;
            border-width: 3px;
            margin-top: 0;

            &:hover {
              background-color: @button-cta-active;
              border-color: @button-cta-active;
            }
          }
        }
      }
    }
  }

  .region-blockgroup-footer-row-1-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    > * { margin-bottom: 1.8rem; }

    .footer-menu {
      flex: 1 0 100%;
      margin-bottom: 2.9rem;
      .menu-block-wrapper {
        .menu {
          .unbullet();
          margin: 0;
        }
        > .menu {
          .unbullet();
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;
          width: 100%;

          > li {
            flex: 0 1 auto;
            max-width: 300px;
          }
          &:after {
            content: ' ';
            flex: 1 0 120px;
            max-width: 120px;
          }
        }
      }
    }

    &:after {
      content: '.';
      visibility: hidden;
      max-width: 300px;
      flex: 0 1 121px;
    }

    .footer-contact,
    .footer-address {
      flex: 0 1 auto;
      max-width: 300px;
    }
  }

  .region-blockgroup-footer-row-2-inner {

    text-align: center;

    .footer-social {
      margin-bottom: 3.5rem;

      .content {
        display: flex;
        align-items: center;
        justify-content: space-around;
        max-width: 300px;
        margin: 0 auto;
      }
      .icon {
        margin: 0 10px;
      }
    }

    .footer-legal {
      .content > *:first-child {
        margin-bottom: .875rem;
      }
    }

  }
}

.mobile-footer() {
    .menu-block-wrapper {
      .menu {
        .unbullet();
        margin: 0;
      }
    }
  .menu-block-wrapper > .menu > li > .menu {
    display: none;
  }

  .region-footer-first {
    .block > .content > * {
      text-align: center;
    }
  }

  .footer-subscribe {
    .subscribe-wrapper {

      > h4 {
        text-align: center;
        margin-bottom: 1.5rem;
      }
      > .manual-form {

        #subscribe-form {
          max-width: 100%;
          margin: 0 auto;

          input[type="text"] {
            border-right: none;
            padding: 0.625rem 1.3rem 0.5rem;
            font-weight: 600;
          }

          input[type="submit"] {
            padding: 0.6rem 2em 0.7rem;
            background-color: @button-color;
            color: @white;
            border-width: 3px;
            margin-top: 1.3rem;

            &:hover {
              background-color: @button-cta-active;
              border-color: @button-cta-active;
            }
          }
        }
      }
    }
  }

  .footer-contact,
  .footer-address {
    margin-bottom: 2.85rem;
  }

  .region-blockgroup-footer-row-2-inner {

    text-align: center;

    .footer-social {
      margin-bottom: 3.5rem;

      .content {
        display: flex;
        align-items: center;
        justify-content: space-around;
        max-width: 300px;
        margin: 0 auto;
      }
      .icon {
        margin: 0 10px;
      }
    }

    .footer-legal {
      .content > *:first-child {
        margin-bottom: .875rem;
      }
    }

  }
}




.section-footer {
  color: @white;
  * { color: inherit; }

  .block-footer-title .content{
    display: none;
  }

  .region-footer-first {
    .block > .content h4 {
      color: @white;
      line-height: 1.4;
    }
  }

  .footer-subscribe {
    .subscribe-wrapper {
      > .manual-form {
        #subscribe-form {
          input[type="text"] {
            padding: 0.625rem 1.3rem 0.5rem;
            font-weight: 600;
          }

          input[type="submit"] {
            padding: 0.45rem 1em 0.45rem;
            background-color: @button-color;
            color: @white;
            border-width: 3px;
            min-width: 100px;

            &:hover {
              background-color: @button-cta-active;
              border-color: @button-cta-active;
            }
          }
        }
      }
    }
  }

  .footer-social {
    .icon {
      display: inline-block;
      vertical-align: middle;
      position: relative;

      img {
        width: auto;
        height: 1.5rem;
        position: relative;
        z-index: 1;
        opacity: 1;
        .trans();

        &[src$="youtube-wordmark--white.svg"] {
          height: 1.75rem;
        }
      }

      &:before {
        content: ' ';
        .abs-0();
        background-repeat: no-repeat;
        background-color: transparent;
        background-position: center;
        background-size: cover;
        z-index: 0;
        opacity: 0;
        .trans();
      }
      &.facebook-link:before { background-image: @facebook-reversed--aqua; }
      &.twitter-link:before { background-image: @twitterbird--aqua; }
      &.youtube-link:before { background-image: @youtube-wordmark--aqua; }
      &.rss-link:before { background-image: @rss--aqua; }

      &:hover {
        img { opacity: 0; }
        &:before {
          opacity: 1;
        }
      }
    }
  }

  .footer-legal {
    &, > .content { .fine-print(); }
  }


  .region-footer-second {
    h4 {
      .footer-block-title();
      line-height: 1.4;

      .resp(@mobilemax, {
        margin-bottom: 1.25rem;
      });
    }
  }
  .menu-block-wrapper {
    .menu a {
      .footer-list-item();
    }
    > .menu > li > a {
      .footer-block-title();

      .resp(@goldilocks, {
        margin-bottom: 1.4rem;
      });
    }
  }


  .resp-min(@berenstain + 1, {
    .desktop-footer();
  });

  .resp-range(@goldilocks + 1, @berenstain, {
    .tablet-footer();
  });

  .resp(@goldilocks, {
    .mobile-footer();
  });
}