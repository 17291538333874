
// Date Picker
// ================================

@month-next-icon: @arrow-right;
@month-prev-icon: @arrow-left;

.date-picker(@prev: @month-prev-icon, @next: @month-next-icon) {
  padding: 1.5rem;
  width: 100%;
  max-width: 22rem;
  background: @white;
  .subtle-box-shadow();

  table {
    font-size: 100%;

    td > a:hover {
      background-color: fade(@brand-mono, 7.5%);
    }
  }

  .ui-datepicker-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .ui-datepicker-prev,
    .ui-datepicker-next {
      .abs-reset(relative);
      width: 2rem;
      height: 2rem;
      background-color: transparent;
      background-position: center;
      background-size: auto;
      background-repeat: no-repeat;
      overflow: hidden;
      cursor: pointer;
      .ui-icon {
        .element-invisible;
      }
    }

    .ui-datepicker-prev {
      background-image: @prev;
    }
    .ui-datepicker-next {
      order: 100;
      background-image: @next;
    }
    .ui-datepicker-title {
      margin: 0;
      width: 100%;

      .ui-datepicker-month,
      .ui-datepicker-year {
        // width: auto;
        width: 44%;
      }

      .ui-datepicker-month {
        margin: 0;
        margin-right: .25rem;
      }
      .ui-datepicker-year {
        margin: 0;
        margin-left: .25rem;
      }
    }
  }

}

.ui-datepicker {
  .date-picker();
}