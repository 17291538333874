.desktop-header() {
  background-color: @contrast-bg;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  .clearfix {
    &:before,
    &:after {
      content: none;
    }
  }

  .region-inner {
    max-width: none;
  }

  > * {
    flex: 1 0 auto;
  }

  .quick-menu {
    display: none;
  }

  .menu {
    .unbullet();
  }

  // Zone Header
  .zone-header-wrapper {
    flex: 1 1 auto;
    max-width: 25%;
    order: 5;
    padding: 0;
    padding-right: 64px;
    padding-left: 75px;
    width: 25%;
    align-items: center;
    display: flex;

    // width: 330px;
    .region-inner {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;

      > * {
        flex: 1 0 auto;
        width: 100%; // Safari fix?
      }

      > .mobileMainMenuToggle {
        display: none;
      }

      > .block-mobile-search {
        display: none;
      }

      // > .block-logo {
      //   flex-basis: 50%;
      // }
    }
  }

  // Zone Branding
  .zone-branding-wrapper {
    display: flex;
    // flex: 1 0 auto;
    flex: 0 0 auto; // Safari
    justify-content: space-between;
    order: 10;
    padding: 0;
    padding-right: 75px;
    width: 75%;

    .zone-branding,
    .region-branding {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
  .region-branding-inner {
    display: flex;
    flex-basis: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: static;

    > * {
      flex: 0 1 auto;
    }
  }
  .mobile-top-level-nav {
    display: none;
  }
  .top-level-nav {
    display: flex;
    position: static;

    .block-title {
      display: none;
    }

    > .content,
    .menu-block-wrapper,
    .menu-block-wrapper > .menu,
    .menu-block-wrapper > .menu > li,
    .tlAccSwitchWrap {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    > .content {
      &:before,
      &:after {
        content: none;
      }
    }

    .menu-block-wrapper > .menu > li > a,
    .menu-block-wrapper > .menu > li > .nolink,
    .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
    .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink {
      align-items: center;
      display: flex;
    }

    .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > a,
    .menu-block-wrapper > .menu > li > .tlAccSwitchWrap > .nolink {
      align-items: center;
      display: flex;
      flex: 0 1 auto;
      max-width: 100%;
      width: auto;
    }
  }

  .header__flex {
    flex-direction: column;
    justify-content: flex-end;

    text-align: right;
    margin: 20px 0;

    > * {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .block-search {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding: 0;

    > * {
      flex: 1 0 auto;
    }
  }



  // Zone menu
  .zone-menu-wrapper {
    background-color: @contrast-shade-bg;
    flex-basis: 100%;
  }
  .region-menu-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width: 100%;
    padding: 0 75px;

    > * {
      flex: 1 0 auto;
      &:nth-child(odd),
      &[aria-hidden]:first-child ~ :nth-child(even) {
        text-align: left;
      }
      &:nth-child(even),
      &[aria-hidden]:first-child ~ :nth-child(odd) {
        text-align: right;
      }
    }
  }

  .menu-block-wrapper > .menu > li {
    display: inline-block;
  }

  .secondary-menu,
  .user-menu {
    .menu-block-wrapper > .menu {
      letter-spacing: -1em;
      > * {
        letter-spacing: normal;
      }
    }
  }

  .secondary-menu .menu a {
    .fine-menu-link(@white);

    margin-right: 2.25rem; //36px
    margin-left: auto;
  }

  .user-menu .menu a,
  .user-menu .menu li > span {
    .fine-menu-link(@aqua);

    margin-right: auto;
    margin-left: 2.25rem; //36px
  }
}

.tablet-header() {
  background-color: @contrast-shade-bg;
  position: relative;

  .menu {
    .unbullet();
  }

  .region-inner {
    max-width: none;
  }

  .zone-menu-wrapper {
    display: flex;
    flex: 1 0 100%;
    flex-wrap: wrap;
    padding: 0 28px;

    .block-menu-block {
      margin-top: 3px;
      padding: 11px 0 14px;
      position: relative;

      &:before {
        .abs(0, 27px, auto, 27px);

        background-color: transparent;
        content: ' ';
        display: block;
        height: 2px;
      }

      ~ .block-menu-block {
        &:before {
          background-color: @white;
        }
      }

      &:first-child[aria-hidden] + .block-menu-block {
        &:before {
          background-color: transparent;
        }
      }
    }

    .menu-block-wrapper {
      position: relative;

      &.menu-name-main-menu {
        padding-top: .55rem;
      }

      > .menu > li {
        > a,
        > .nolink {
          .mobile-top-menu-link();

          font-weight: 400;
          padding: .5rem 27px;

          .menu-item-inner {
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .quick-menu {
    display: none;
  }

  // Zone Header
  .zone-header-wrapper {
    flex-basis: 100%;
    order: initial;
    padding: 0;

    .region-inner {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      > * {
        align-items: center;
        display: flex;
        flex: 0 1 auto;
        justify-content: center;
      }
      > .block-logo {
        flex-basis: auto;
        padding-right: 14px;
        padding-left: 14px;
        width: 30%;
        > .content {
          height: 100%;
          width: 100%;
        }
      }
      > .mainSearchToggle {
        display: none;
      }
      > .block-mobile-search {
        flex: 1 0 0;
        padding-right: 24px;
        padding-left: 24px;
      }
    }
  }
  .mobile-top-level-nav {
    display: none;
  }
  .top-level-nav {
    .block-title {
      display: none;
    }
  }
  .zone-branding-wrapper {
    background-color: @contrast-shade-bg;
    order: initial;
    padding: 0;
  }

  .region-branding-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    > * {
      flex: 1 1 0;
    }

    .top-level-nav {
      background-color: @contrast-bg;
      flex: 1 0 100%;
      order: 10;
      width: 100%;

      > .content,
      .menu-block-wrapper,
      .menu-block-wrapper > .menu,
      .tlAccSwitchWrap {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        // flex: 1 1 0%;
        margin: 0 auto;
      }

      > .content {
        &:before,
        &:after {
          content: none;
        }
      }

      .menu-block-wrapper > .menu >  {
        justify-content: center;
      }

      .menu-block-wrapper > .menu > li {
        display: flex;
      }
    }

    .block-search-form,
    .block-search[id*='-form'] {
      display: none;
    }
  }

  // Zone menu
  .zone-menu-wrapper {
    .abs(91px, 0, auto, 0);

    background-color: @brand-primary;
    flex-basis: 100%;
    z-index: 100;
    a {
      color: @white;
    }
  }
  .region-menu-inner {
    display: block;
  }
  .menu-block-wrapper > .menu > li {
    display: inline-block;
  }
}

.mobile-header() {
  background-color: @contrast-shade-bg;

  .menu {
    .unbullet();
  }
  .zone-menu-wrapper {
    background-color: @brand-primary;
  }

  .top-level-nav {
    .element-invisible();
  }
  .block-search[id*='-form'] {
    display: none;
  }
  .quick-menu {
    .unbullet();

    background-color: @contrast-bg;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0;
    overflow: auto;
    > * {
      flex: 0 0 auto;
      text-align: center;
    }

    a {
      .mobile-quick-menu-link();
    }
  }

  // Zone Header
  .zone-header-wrapper {
    flex-basis: 100%;
    order: initial;
    padding: 0;

    .region-inner {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      position: relative;
      > * {
        align-items: center;
        display: flex;
        flex: 0 1 auto;
        justify-content: center;
      }
      > .block-logo,
      > .block[id*='logo'] {
        flex-basis: auto;
        padding: 0;
      }
      .toggleSwitch {
        //flex-basis: 40px;
      }

      .block-mobile-search {
        .abs-0();

        align-items: center;
        background-color: @contrast-tint-bg;
        display: flex;
        padding: 0 17px;
        z-index: 1100;
        > .block-search-form {
          flex-basis: 100%;
        }

        &.isClosed {
          display: none;
        }
        &.isOpen {
          display: flex;
        }
      }
    }
  }

  .mobile-top-level-nav,
  .top-level-nav {
    .block-title {
      .mobile-menu-block-title();
    }
  }

  .menu-block-wrapper {
    border-top: 2px solid;
    padding: .9rem 0 1.05rem;
    &.menu-name-main-menu {
      padding-top: .55rem;
    }

    > .menu > li {
      > a,
      > .nolink {
        .mobile-top-menu-link();
      }
    }
  }
}

.section-header {
  .resp-min(@tabletmax + 1, {
    .desktop-header();
  });
  .resp-range(@mobilemax + 1, @tabletmax, {
    .tablet-header();
  });
  .resp(@mobilemax, {
    .mobile-header();
  });
}

@media only screen and (min-width: 1400px) {
  .block-search {
    width: 407px;
  }
}

@media only screen and (min-width: 1680px) {
  .block-search {
    width: 541px;
  }
}

@media only screen and (min-width: 1920px) {
  .block-search {
    width: 623px;
  }
}
