.block-search[id*="-form"],
.block-mobile-search {
  font-size: @base-font-size;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 1 auto;
  .resp(@tabletmax, {
    flex: 0 1 100%;
  });

  > .content {
    flex: 1 0 0%;
  }
  > .altSearchCloseButton {
    display: none;
    width: 48px;
    padding-left: 4px;
    padding-right: 12px;

    .resp(@mobilemax, { display: flex; });
    align-self: stretch;
    align-items: center;
    order: -10;
    justify-content: center;


    .text {
      text-indent: -9999px;
      display: block;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      position: relative;
      &:before, &:after {
        content: ' ';
        height: 3px;
        width: 22px;
        border-radius: 2000px;
        background-color: @white;
        transform-origin: center center;
        position: absolute;
        top: 50%;
        left: 0;
        right: auto;
      }
      &:before {
        .t-y(-50%);
        .transform(rotate(45deg));
      }
      &:after {
        .t-y(-50%);
        .transform(rotate(-45deg));
      }
      .text {
        white-space: nowrap;
        text-indent: -9999px;
        display: block;
        .abs(-1rem,-1rem,-1rem,-1rem);
      }
    }
  }

  form {
    position: relative;
    .resp-min(@tabletmax + 1,  {
      top: -2px;
    });

    // This is where all of the form items are
    > div > .container-inline {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      background-color: fade(@search-bg-color, 90%);
      &.hasFocus {
        // See global.js
        background-color: @search-bg-color;
      }

      .resp(@mobilemax, {
        background-color: @mobile-search-bg-color;
        border: 3px solid @mobile-search-border-color;

        &.hasFocus {
          background-color: @mobile-search-bg-color;
        }
      });

      // Text form item
      [class*="form-item-search-block-form"] {
        order: 2;
        flex: 1 0 auto;
      }
      // Select form item
      [class*="form-item-search-select"] {
        display: flex;
        flex-direction: column;
        justify-content: center;

        select {
          flex: 0 0 auto;
          border: none;
          background-color: transparent;
          background-image: @dropdown-arrow--gray;
          background-position: right 1rem top 50%;
          background-size: 10px auto;
          padding: .75rem 1rem .5rem;
          padding-right: 2rem;
          font-family: @display-font;
          font-size: .7rem;
          letter-spacing: .15em;
          font-weight: 600;
          text-transform: uppercase;
          margin: 0;
          margin-right: 6px;
          height: 100%;
          line-height: 1;
        }
      }

    }
  }

  [class*="form-item-search-block-form"] {
    display: block;

    input[type="text"] {
      border: none;
      padding-left: 20px;
      padding-right: 74px;
      color: @search-text-color;
      //background-color: fade(@search-bg-color, 90%);
      background-color: transparent;
      border: none;
      height: 39px;
      .trans(background-color);

      .resp-min(@tabletmax + 1, {
        height: 40px;
      });

      &:focus {
        //background-color: @search-bg-color;
      }

      .placeholder({
        color: @search-placeholder-color;
      });

      .resp(@mobilemax, {
        padding-right: 60px;
        color: @mobile-search-text-color;

        .placeholder({
          color: @mobile-search-placeholder-color;
        });
      });

    }
  }

  .form-actions {
    .abs(0,0,0,auto);
    overflow: hidden;
    width: 64px;
    .resp(@tabletmax, {
      width: 54px;
    });
    .resp(@mobilemax, {
      width: 40px;
    });

    .form-submit {
      background-color: transparent;
      background-image: @search;
      background-repeat: no-repeat;
      background-position: 50% 46%;
      background-size: 20px 20px;
      .resp-min(@tabletmax + 1, {
        background-position: 50% 50%;
        background-size: 22px 22px;
      });
      color: transparent;
      text-shadow: none;
      box-shadow: none;
      width: 100%;
      height: 40px;
      border: none;

      &:hover {
        transform: none;
        background-image: @search--teal;
      }
    }
  }

  &.togglePane.hasToggle {

    .resp(@mobilemax, {
      top: 0;
      left: 0;
      right: 0;
      overflow: hidden;
    });

    &.hasClose {
      padding-right: 50px;
      right: 0;

      .resp(@mobilemax, {
        padding-right: 60px;
      });

      .close {
        .abs(0, 0, 0, auto);
        width: 50px;
        .resp(@mobilemax, {
          width: 60px;
        });
        background-color: transparent;
        background-image: @small-x--white;
        background-size: 18px 18px;
        background-repeat: no-repeat;
        background-position: center;

        .text {
          .element-invisible;
        }
      }
    }
  }

}
