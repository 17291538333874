
.views-exposed-form {
  .clearfix {
    line-height: 0;
  }

  .views-exposed-widget {
    float: none;
    padding: 0;
    line-height: @base-line-height;
  }

  > .views-exposed-widgets {
    letter-spacing: -1em;
    margin: -.75rem;

    .region[class*="sidebar"] & {
      letter-spacing: normal;
      margin: auto;
    }

    > .views-exposed-widget {
      letter-spacing: normal;
      width: 50%;
      display: inline-block;
      vertical-align: top;
      padding: .75rem;

      .region[class*="sidebar"] & {
        width: 100%;
        display: block;
        padding: 0;
      }

      .resp(@mobilemax, {
        width: 100%;
        padding: .6em .75em;

        .region[class*="sidebar"] & {
          padding: 0;
        }
      });

      &.views-submit-button {
        padding: 0;
      }
    }
  }

  .form-item {
    margin-bottom: 0;
  }

  .form-type-select .description {
    .element-invisible;
  }
}

.reset-views-exposed-form() {
  .views-exposed-form {
    margin-bottom: 0;

    .no-sidebars & {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;

      .resp(@tabletmax, {
        max-width: 100%;
        padding: 0;
      });
    }

    .views-exposed-widget {
      float: none;
      padding: 0;
    }

    > .views-exposed-widgets {
      letter-spacing: normal;
      margin: 0;
      position: relative;

      > .views-exposed-widget {
        letter-spacing: inherit;
        width: 100%;
        display: block;
        vertical-align: top;
        padding: 0;

        .resp(@mobilemax, {
          width: 100%;
          padding: 0;
        });

        &.views-submit-button {
          padding-top: 0;

          // If auto submit is enabled on form
          // remove padding
          [class*="auto-submit"]& {
            padding: 0;
          }
        }
      }

      .ajax-progress {
        .abs-0();
        z-index: 40;
        background-color: fade(@faint-bg, 90%);
        animation: fadein 500ms;
        .throbber {
          .abs-0();
          background: none;
          background-image: @triple-dots--navy;
          background-position: center;
          background-repeat: no-repeat;
          margin: 0;
          height: auto;
          width: auto;
          float: none;
          animation: fadeinout 500ms infinite;
        }
      }
    }
  }
}