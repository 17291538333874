

// Chosen
// ================================

//* @group Base *//
.chosen-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  &:extend(.input);
  zoom: 1;
  *display: inline;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 100% !important;

  .chosen-drop {
    position: absolute;
    top: 100%;
    left: -9999px;
    z-index: 1010;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid;
    border-top: 0;

    box-shadow: 0px 0px 2px @section-break-color;
    &:before {
      content: ' ';
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      height: 2px;
    }
  }
  &.chosen-with-drop .chosen-drop {
    left: 0;
  }

  a {
    cursor: pointer;
  }
}

//* @end .chosen-container *//

//* @group Single Chosen *//
.chosen-container-single {
  .chosen-single {
    position: relative;
    display: table;
    width: 100%;
    overflow: hidden;
    padding: @inputpadding;
    height: @inputheight;
    border: 1px solid;
    border-radius: 0;

    background-clip: padding-box;
    box-shadow: none;

    text-decoration: none;
    white-space: nowrap;
    &:extend(.select-option);

    span {
      display: table-cell;
      vertical-align: middle;
      overflow: hidden;
      margin-right: 26px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    abbr {
      position: absolute;
      top: 6px;
      right: 26px;
      display: block;
      width: 12px;
      height: 12px;
      font-size: 1px;
      &:hover {
        background-position: -42px -10px;
      }
    }

    div {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 18px;
      height: 100%;

      b {
        display: block;
        width: 100%;
        height: 100%;
        background: transparent;

        .icon-before(@arrow-down);
      }
    }
  }

  .activeSelection & .chosen-single {
    &:extend(.select-option);

    letter-spacing: 1px;
  }

  .chosen-single-with-deselect span {
    margin-right: 38px;
  }

  .chosen-default {
    color: #999;
  }

  &.chosen-disabled .chosen-single abbr:hover {
    background-position: -42px -10px;
  }

  .chosen-search {
    position: relative;
    z-index: 1010;
    margin: 0;
    padding: 0;
    white-space: nowrap;

    input[type="text"] {
      box-sizing: border-box;
      margin: 1px 0;
      padding: @inputpadding;
      width: 100%;
      height: auto;
      outline: 0;
      border: none;

      &:extend(.select-option);
      border-radius: 0;
    }
  }

  .chosen-drop {
    margin-top: -1px;
    border-radius: 0;
    background-clip: padding-box;
  }

  &.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    left: -9999px;
  }
}

//* @end .chosen-single *//


//* .chosen-container-multi *//
.chosen-container-multi {
  .chosen-choices {
    border: none;
    display: none;
  }
  .chosen-drop {
    border: none;
    color: @faint-text-color;

    .chosen-results > li {
      position: relative;
      padding-left: 30px;
      padding-top: 12px;

      &:before {
        content: ' ';
        display: inline-block;
        width: 12px;
        height: 12px;
        border: 1px solid @hr-color;
        border-radius: 1px;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        margin-top:-2px;
      }

      &.highlighted:before {
        border-color: @white;
      }
    }
    .result-selected {
      color: @medium-text-color;
      opacity: .25;

      &:before {
        background-image: @small-x;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 8px;
      }
    }
  }
}
//* @end .chosen-container-multi *//


/* @group Results */
.chosen-container .chosen-results {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  max-height: 240px;
  -webkit-overflow-scrolling: touch;

  li {
    display: none;
    margin: 0;
    padding: @inputpadding;
    &:extend(.select-option);
    -webkit-touch-callout: none;

    &.highlighted {
      color: @medium-text-color;
      background-color: @brand-primary;
    }

    &.active-result {
      display: list-item;
      cursor: pointer;
    }
    &.disabled-result {
      display: list-item;

      cursor: default;
    }
    &.highlighted {
      background-image: none;

    }
    &.no-results {
      display: list-item;

    }
    &.group-result {
      display: list-item;
      font-weight: bold;
      cursor: default;
    }
    &.group-option {
      padding-left: 15px;
    }
    em {
      font-style: normal;
      text-decoration: underline;
    }
  }
}

//* @group Active  *//
.chosen-container-active {
  .chosen-single {
    border: 1px solid;
    box-shadow: 0px 0px 2px @section-break-color;
  }

  &.chosen-with-drop .chosen-single {
    border: 1px solid;
    border-radius: 0;
    background-image: none;
    box-shadow: 0 2px 2px @section-break-color;
    overflow: visible;
    &:after {
      content: ' ';
      position: absolute;
      bottom: -3px;
      left: -2px;
      right: -2px;
      height: 3px;

    }

    div {
      border-left: none;
      background: transparent;

      b {
        .icon-before(@arrow-up);
      }

    }
  }

  .chosen-choices {
    border: 1px solid;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

    li.search-field input[type="text"] {
      color: #111 !important;
    }
  }
}

//* @end .chosen-container-active *//

/* @group Disabled Support */
.chosen-disabled {
  opacity: 0.5 !important;
  cursor: default;
}
.chosen-disabled .chosen-single {
  cursor: default;
}
.chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}

/* @end */