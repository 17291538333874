.context-users {
  .db-access-wrap {
    padding: 0;
    float: right;
    background: none;
    width: 100px;
    a {
      .button;
    }
  }
  .profile {

    > div,
    > .user-profile-category {
      .card-style-post();
    }
    > .block {
      .list-style(@title_el: ~".block-title", @body: ~".content");
      .menu {
        .unbullet;
        margin-top: 1rem;
        li {
          .views-rows-ruled(@row_el: ~'li');
          a {
            .icon-inline-after(@arrow-right--gray);
            display: block;
            .resp(@tabletmax, { display: block; });
            &:hover {
              .icon-inline-after(@arrow-right--teal);
              display: block;
              .resp(@tabletmax, { display: block; });
            }
          }
        }
      }
      .item-list .menu li {
        display: block;
      }
      margin-bottom: 2rem;
      // .block-title {
      //   text-align: left;
      // }
    }
  }
  .zone-content .grid-3.region.region-sidebar-second {
    margin-top: 0;
    .region-inner {
      .white-sidebar-blocks();

      .region-to-block,
      div.block {
        // padding: 2.1rem 2rem;
        // text-align: left;
        // .field-label,
        // .block-title {
        //   text-align: left;
        // }

        // AJC : Ticket #: JLL-713
      }
      .region-to-block {
        background: @faint-bg;
        margin-top: 3rem;
        .list-style(@title_el: ~".field-label", @body: ~".field-contents");
        .field-contents {
          margin-top: 0;
        }
        .entity-field-collection-item {
          .unbullet;
          ul {
            margin-top: 1rem;
            display: block;
          }
        }
      }
      .block-recent-databases-block {
        // .list-style(@title_el: ~".block-title", @body: ~".views-row");
      }
    }
    &.region.grid-3:before {
      content: none;
    }
  }
  .region-postscript-second {
    text-align: left;
    .block-title {
      text-align: left;
    }
  }
}

.db-access-wrap {
  text-align: center;

  > span, > a {
    width: 6.5rem;
  }
  a {
    .button-cta();
  }
}
