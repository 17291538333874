//Styling for home content band on the front page -- at the top

//Mobile styles
@media screen and (max-width: 480px) {
  .grid.col-2 .grid-container .views-row {
    display: block;
  }

  .view-id-home_band.view-display-id-blog_homepage .view-mode-teaser.node-blog-post .field[class*="signature"] img {
    width: 100%!important;
    padding-bottom: 15px;
  }

  .view-id-home_band .field.field-signature-image {
    margin-top: 0.3rem !important;
  }

  .view-id-home_band.view-display-id-blog_homepage.grid.col-2 img {
    margin: 0;
    padding: 0;
  }
}

@media screen and (min-width: 481px) {
  .grid.col-2 img {
    width: 40%!important;
    float: left;
    padding-right: 15px;
  }
}


@media screen and (min-width: 700px) {

.grid.col-1 img{
  width: 30%!important;
  float: left;
  padding-right: 15px;
}

}

.grid.col-2 .grid-container .views-row {
  //font-size: 16px;
  display: flex!important;
  width: 100%!important;
  //vertical-align: top;
  //padding: 20px;
}

div#block-views-home-band-blog-homepage {
  padding-bottom: 50px;
}

.front .view-mode-teaser.node-blog-post .field-title {
  margin-bottom: 1rem;
}

.clearfix:after,
Placing image and text side by side .cf:after {
  clear: none;
}

.view-id-home_band.view-display-id-blog_homepage .view-mode-teaser.node-blog-post {
  // padding: 0.05rem 1.5rem 1rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  //min-height: 240px;
}
//hiding meta data on home band

.view-id-home_band.view-display-id-blog_homepage .group-meta {
  display: none;
}
// Placing image and text side by side
.cf:after,
.clearfix:after {
  clear: none;
}

.view-id-home_band.view-display-id-blog_homepage .view-mode-teaser.node-blog-post .field-title a {
  color: #028095;
}


.grid.col-1 h4 {
  /* float: right; */
  padding: 5px;
}

.view-display-id-blog_homepage .field[class*="signature"] .entity > .field{
  margin: 0!important;
}
