
.page-contact {
  form > div {

    > .form-wrapper {
      &[id*="edit-primary"],
      &[id*="edit-details"],
      &[id*="edit-copyright"],
      &[id*="edit-payment"] {
        .card-style-post();
      }
    }
  }
  .region-sidebar-second {
    .region-inner {
      .white-sidebar-blocks();
    }
  }
  .resp(@tabletmax, {
    .container-12 .grid-3.region.region-sidebar-second {
      padding-top: 2.75rem;
    }
  });
  // .ui-datepicker {
  //   .ui-datepicker-prev,
  //   .ui-datepicker-next {
  //     left: inherit;
  //     top: inherit;
  //     margin-left: -10px;
  //     margin-right: 10px;
  //     margin-top: 33px;
  //   }
  //   .ui-datepicker-month,
  //   .ui-datepicker-year {
  //     width: 100%;
  //   }
  // }

  .region-content.grid-12 {
    width: 74%;
    flex-basis: 74%;
    min-width: 74%;
    margin: 0;

    .resp(@tabletmax, {
      width: 100%;
      min-width: 100%;
      padding-right: 0;
      flex-basis: 100%;
    });
  }
}

