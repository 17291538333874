
// Tables
// ================================

.table {
  .horizontal-padding() {
    @padding-h: 1.0625rem;
    @padding-h-first: @padding-h;
    @padding-h-last: @padding-h;

    .first-child-padding-h() {
      padding-left: @padding-h-first;
      padding-right: @padding-h;
    }
    .last-child-padding-h() {
      padding-left: @padding-h;
      padding-right: @padding-h-first;
    }
  }
  .horizontal-padding();

  @th-padding: @padding-h @padding-h;
  @td-padding: @padding-h @padding-h 1.4375rem;

  .th(@background-color: @tan) {
    background-color: @background-color;
    padding: 0;
    padding: @th-padding;
    text-align: center;
    border: 1px solid @grey;

    &:first-child {
      .first-child-padding-h();
    }
    &:last-child {
      .last-child-padding-h();
    }

    color: @text-color;

    a {
      display: block;
      position: relative;
      color: inherit;
      span { border: none; }

      // tap target
      &:before {
        @top: -1 * extract(@th-padding, 1);
        @bottom: -1 * extract(@th-padding, 1);
        @left: -1 * extract(@th-padding, 2);
        @right: -1 * extract(@th-padding, 2);
        content: ' ';
        .abs(@top, @right, @bottom, @left);
      }
    }
  }

  .td {
    padding: @td-padding;
    text-align: left;
    border: 1px solid @grey;
    &:first-child {
      .first-child-padding-h();
      max-width: 36.425rem;
    }
    &:last-child {
      .last-child-padding-h();
    }
  }

  .stripe-tds(@odd-color: fade(@brand-mono, 6%); @even-color: fade(@dark-bg, 6%)) {
    tbody > tr:nth-child(odd) > td {
      background-color: @odd-color;
    }
    tbody > tr:nth-child(even) > td {
      background-color: @even-color;
    }
  }
}

.data-table() {
  .table();
  .stripe-tds();

  tbody tr { border: none; }

  th, td {
    .horizontal-padding();
    &:first-child { .first-child-padding-h(); }
    &:last-child { .last-child-padding-h(); }
  }
  th {
    .th();
  }
  td {
    .td();
  }
}



/* --- [ Tablesaw ] --------------- */

.tablesaw-stack-max() {
  &.tablesaw-stack tbody tr {
    border: none;
  }
  &.tablesaw-stack thead td,
  &.tablesaw-stack thead th {
    display: none;
  }
  &.tablesaw-stack tbody td,
  &.tablesaw-stack tbody th {
    clear: left;
    float: left;
    width: 100%;
  }
  .tablesaw-cell-label {
    display: inline-block !important;
    vertical-align: top;
  }
  .tablesaw-cell-content {
    max-width: 67%;
    display: inline-block;
  }
  &.tablesaw-stack td:empty,
  &.tablesaw-stack th:empty {
    display: none;
  }
}

.tablesaw-stack-min() {
  &.tablesaw-stack tr {
    display: table-row;
  }
  &.tablesaw-stack tbody tr {
    border: none;
  }
  /* Show the table header rows */
  &.tablesaw-stack td,
  &.tablesaw-stack th,
  &.tablesaw-stack thead td,
  &.tablesaw-stack thead th {
    display: table-cell;
    margin: 0;
  }
  /* Hide the labels in each cell */
  &.tablesaw-stack td .tablesaw-cell-label,
  &.tablesaw-stack th .tablesaw-cell-label {
    display: none !important;
  }
}

.tablesaw-stack(@breakpoint: @tabletmax;) {
  .resp(@breakpoint, {
    .tablesaw-stack-max();
  });
  .resp-min(@breakpoint + 1, {
    .tablesaw-stack-min();
  });
}


.tablesaw() {
  .table();
  thead tr th,
  thead tr:first-child th,
  td {
    .horizontal-padding();
    &:first-child { .first-child-padding-h(); }
    &:last-child { .last-child-padding-h(); }
  }

  thead { tr { &, &:first-child { th { .th(); } } } }
  td {
    .td();
  }
  th {
    .th();
  }

  .resp(@tabletmax, {
    border-collapse: collapse;

    &.tablesaw-stack {
      td {
        &, &:first-child, &:last-child {
          text-align: left;
          padding: .4rem 2rem;
          max-width: 100%;
        }
        &:first-child { padding-top: 1.8rem; }
        &:last-child { padding-bottom: 1.8rem; }
      }

      .tablesaw-cell-label {
        .meta();
        color: @medium-text-color;
        margin: 0;
        margin-top: .6rem;
        padding: 0;
        padding-right: 1.5rem;
        display: inline-block;
        width: 30%;
        overflow: hidden;
        text-overflow: ellipsis;

        a {
          color: inherit;
          .text { border: none; }
        }
      }

      .tablesaw-cell-content {
        display: inline-block;
        width: 70%;
      }
    }
  });

  .resp(@baby_bear, {
    &.tablesaw-stack {
      .tablesaw-cell-label {
        .meta();
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        a {
          color: inherit;
          .text { border: none; }
        }
      }

      .tablesaw-cell-content {
        display: inline-block;
        width: 100%;
      }
    }
  });

  .resp(@mobilemax, {
    &.tablesaw-stack {
      td {
        &, &:first-child, &:last-child {
          padding-left: 1.1rem;
          padding-right: 1.1rem;
        }
      }
    }
  });
}

.tablesaw {
  .tablesaw();
  .tablesaw-stack();
}


.wysiwyg-table {
  &:not(:first-child) {
    margin-top: 1.6rem;
  }
  .data-table();
  width: 100%;
  td {
    &, &:first-child, &:last-child {
      padding-top: .75rem;
      padding-bottom: .75rem;
    }
  }
  &[style*="width"] {
    width: 100% !important;
  }
}
