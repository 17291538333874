html {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  overflow-x: hidden;
}

* { box-sizing: border-box; }

body {
  .font-smoothing;


  color: @text-color;
  font-family: @base-font;
  font-size: @base-font-size;
  line-height: @base-line-height;
  font-weight: 400;

  .resp(@mobilemax, {
    font-size: @mobile-base-font-size;
    line-height: @mobile-base-line-height;
  });
}

#page {
  overflow: hidden;
}

#messages,
.messages {
  overflow: auto;
  li:before {
    margin-left: -1rem;
  }
  &.error {
    .error { color: @white; }
  }
}
.error {
  color: @brand-error;
}
.notification-bar(~'.messages',  @severity: normal);
.notification-bar(~'.messages.error',  @severity: high);
.notification-bar(~'.messages.warning',  @severity: warning);
.notification-bar(~'.messages.status',  @severity: success);


// Headings
// ================================

h1, h2, h3, h4, h5, h6 {
  &:first-child,
  a#main-content + &,
  .contextual-links-wrapper + & {
    margin-top: 0;
  }
}


.h1 {
  color: @brand-primary;
  font-size: @h1-size;
  font-weight: 600;
  line-height: @h1-line-height;
  text-transform: none;
  letter-spacing: 0.02em;
}
.h1--small:extend(.h1) {
  font-size: @h1-small-size;
  line-height: @h1-small-line-height;
}
h1 { .h1; }
.resp(@tabletmax, {
  h1 { .h1--small; }
});


.h2 {
  color: @brand-primary;
  font-size: @h2-size;
  font-weight: 700;
  line-height: @h2-line-height;
  text-transform: none;
  letter-spacing: 0.02em;
}
.h2--small:extend(.h2) {
  font-size: @h2-small-size;
  line-height: @h2-small-line-height;
}
h2 { .h2; }
.resp(@mobilemax, {
  h2 { .h2--small; }
});

.h2-aside {
  .h2;
  font-weight: 600;
  .resp(@mobilemax, { .h2--small; });
  color: @medium-text-color;
}


.h3 {
  color: @medium-text-color;
  font-size: @h3-size;
  font-weight: 700;
  line-height: @h3-line-height;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.h3--small:extend(.h3) {
  font-size: @h3-small-size;
  line-height: @h3-small-line-height;
}
h3 { .h3; }
.resp(@tabletmax, {
  h3 { .h3--small; }
});

.h4 {
  color: @dark-text-color;
  font-size: @h4-size;
  font-weight: 600;
  line-height: @h4-line-height;
  text-transform: none;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}
.h4--small:extend(.h4) {
  font-size: @h4-small-size;
  line-height: @h4-small-line-height;
}
h4 { .h4; }

.h4-aside {
  .h4--small;
  color: @medium-text-color;
  font-weight: 700;
  letter-spacing: .02em;
}


.h5 {
  color: @faint-text-color;
  font-size: @h5-size;
  font-weight: 600;
  line-height: @h5-line-height;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
h5 { .h5; }

.h6 {
  color: @medium-text-color;
  font-size: @h6-size;
  font-weight: 400;
  line-height: @h6-line-height;
  text-transform: none;
  letter-spacing: .02rem;
}
h6 { .h6; }

.wysiwyg-heading {
  .h2-aside();
  margin-top: 2.35rem;
  margin-bottom: 1.7rem;
  .resp(@mobilemax, {
    font-size: @h2-small-size;
    line-height: @h2-small-line-height;
  });

  &:first-child {
    margin-top: 0;
  }
}

.wysiwyg-subheading {
  font-size: @h3-size;
  font-weight: 700;
  line-height: 1.6;
  letter-spacing: 0.05em;
  color: @text-color;
  text-transform: none;
  margin-top: 2.35rem;
  margin-bottom: 1.7rem;

  .resp(@mobilemax, {
    font-size: @h3-small-size;
    line-height: 1.6;
  });

  &:first-child {
    margin-top: 0;
  }
}


.subtitle {
  margin: 0;
}

.title-with-fancy-rule {
  overflow: hidden;
  text-align: center;
  margin-bottom: 0;

  + * {
    margin-top: 40px;
    .resp(@tabletmax, {
      margin-top: 30px;
    });
    .resp(@mobilemax, {
      margin-top: 20px;
    });

    &.field-sign-post-text {
      margin-top: 0;
    }
  }

  > * {
    position: relative;
    display: inline-block;
    margin: 0 auto;
    vertical-align: middle;
    max-width: 80%;
    padding: 0 28px;

    &:before,
    &:after {
      content: ' ';
      display: inline-block;
      vertical-align: middle;
      height: 2px;
      width: 1200px;
      position: absolute;
      top: 50%;
      bottom: auto;
      .t-y(~"calc(-50% + 1px)");
      border-top: 1px solid @hr-color;
    }
    &:before {
      right: 100%;
    }
    &:after {
      left: 100%;
    }
  }
}

.fancy-heading-wrapper {
  text-align: center;
  .title-with-fancy-rule;
}
.view-fancy-heading-wrapper {
  .fancy-heading-wrapper;
}

.banner-image {
  .overlay.title {}
  .subtitle {}
}

.select-option:extend(.h4) {}


.field-label,
.label-inline,
.label-above,
.field-group-div > h3 {
  .block-title();
  font-weight: 600;
  margin-bottom: 1.125rem;
}

.field-body,
.block > .content {
  > *:first-child {
    margin-top: 0;
  }

  font-size: @large-copy-size;
  line-height: @large-copy-line-height;

  p {
    > iframe {
      margin-top: 1rem;
      max-width: 100%;
    }
    &:first-of-type > iframe { margin-top: 0; }
  }

  .reset-inline-style() {
    [style*="color"] { color: inherit; }
    [style*="font"] {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
      font-style: inherit;
    }
    [style*="font-size"] { font-size: inherit; }
    [style*="line-height"] { line-height: inherit; }
    [style*="letter-spacing"] { letter-spacing: inherit; }
  }
  .reset-inline-style() !important;
}


// Titling
// ================================

#page-title {
  margin: 0;
  letter-spacing: normal;

  .resp(@tabletmax, {
    text-align: center;
    .meta--type + & {
      text-align: initial;
    }
  });
}
.front #page-title {
  .h2();
  font-weight: 600;
  margin: 0;
  .resp(@mobilemax, {
    .h2--small();
  })
}

.billboard-titling {
  font-family: @display-font;
  font-size: @page-title-size;
  line-height: @page-title-line-height;
  margin: 1.25rem 0 1.5rem;
  .resp(@mobilemax, {
    .h1--small;
  });
}

.block-title-style(@color: true) {
  .h3();
  color: @medium-text-color;
  margin: 0;

  .resp(@tabletmax, {
    .h3--small();
  });

  & when not (@color) {
    color: @color;
  }
  .not-underlined() {
    border: none;
    padding-bottom: 0;
    margin-bottom: 1.25rem;
  }
}
.block-title {
  .block-title-style();

  .zone-footer &,
  .ds-media-aside & {
    .block-title-style(@color: @contrast-heading-color);
  }
}


.aside-subtitle() {
  color: @medium-text-color;
  font-size: @fine-title-size;
  font-weight: 700;
  line-height: @fine-title-line-height;
  letter-spacing: @fine-title-letter-spacing;
  text-transform: uppercase;
}

.footer-block-title() {
  color: @white;
  font-size: @base-font-size;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: .12em;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  margin-bottom: .8rem;
  .font-smoothing(subpixel-antialiased);
  .resp(@mobilemax, {
    margin-bottom: 1.5rem;
  });
}

.block-title-underlined() {
  .block-title();
  padding-bottom: .625rem; // 10px
  border-bottom: 2px solid @hr-color;
  margin-bottom: 1.1875rem; // 19px

  .resp-range(@mobilemax + 1, @tabletmax, {
    margin-bottom: 1rem;
  });
}

.aside-title-underlined() {
  .block-title-underlined();
  margin-bottom: .885rem;
  padding-bottom: .725rem;

  a {
    color: @medium-text-color;
    text-decoration: none;
    .font-smoothing();
    &:hover {
      color: @link-active-color;
    }
  }

  &.containsToggle {
    .resp(@tabletmax, {
      border: none;
      margin: 0;
      padding: 0;
    });
  }
}

[class*="view-mode"] .ds-media .view > .title {
  .block-title();
}

.mobile-menu-block-title {
  margin-bottom: .95rem;
  padding-bottom: 0;
}


.section-heading() {
  .h2();
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 1.5rem; // 24px;

  .resp(@tabletmax, {
    text-align: center;
    margin-bottom: 1.525rem;
  });
  .resp(@mobilemax, {
    .h2--small();
    text-align: center;
    margin-bottom: .875rem; // 14px
  });
}

// See: /
.block[id*="start-tabs"] .block-title {
  .section-heading();
  margin-bottom: 1.6rem;
  .resp(@tabletmax, { margin-bottom: 1.125rem; }); // 18px
  .resp(@mobilemax, { margin-bottom: .925rem; });
}
.region-content,
.region-preface-first,
.region-postscript-second {
  .block-title {
    .section-heading();
  }
}

.front {
  .resp(@tabletmax, {
    .region-preface-first-inner > .content,
    .region-content-inner {
      > .block:not(.block-user) {
        > .content { text-align: center; }
      }
    }
  });
}

.link-set-title {
  // see: /
  .block-title-underlined();
}



.section-footer .block-title {
  .section-heading();
  margin-bottom: 2rem;
  color: @white;
  .resp(@tabletmax, {
    margin-bottom: 2.325rem;
  });
  .resp(@mobilemax, {
    .h2--small();
    color: @white;
    line-height: 1.225;
    margin-bottom: 1.925rem;
  });
}


.billboard-subhead:extend(.h6) {
  .font-smoothing(subpixel-antialiased);
}

.billboard-title:extend(.h1) {
  font-size: 60px;
  line-height: 1;

  .resp(@tabletmax, {
    font-size: 48px;
  });
  .resp(@mobilemax, {
    font-size: 32px;
    line-height: 1.14;
  });
}

.teaser-title:extend(.h5) {
  margin: 0;
  letter-spacing: .5px;
}





// Body
// ================================

.strong-body() {
  .h4();
  font-weight: normal;
}

.region-preface-first,
.front .region-content {
  .block > .content {
    .resp-min(@mobilemax + 1, { .strong-body() });
  }
}

.caption-title,
.caption-body {
  .h6;
  font-size: @caption-font-size;
  line-height: @caption-line-height;
  color: @faint-text-color;
}
.caption-title {
  font-weight: bold;
}
.caption-body {
  font-weight: 400;
}

.slide--counter:extend(.caption-body) {
  color: @white;
}

.image-field-caption {
  margin: 0;
  *:extend(.caption-body) {}
}
.flex-caption:extend(.caption-title) {
  strong:extend(.caption-title) {}
}
.video-embed-description:extend(.caption-title) {
  strong:extend(.caption-title) {}
  padding: 0.85rem 0 1.6rem;
  border-bottom: 2px solid @hr-color;
}

.p {
  font-family: @base-font;
  line-height: inherit;
  font-size: inherit;
  margin-bottom: 0;
  margin-top: 1.6rem;

  &:first-child { margin-top: 0; }
}
.p--small {
  .p();
  font-size: @mobile-base-font-size;
  line-height: @mobile-base-line-height;
}

p {
  .p();
}

.view-mode-full,
[class*="preface-first"] .block > .content {
  .intro {
    .h1();
  }
}

.big-copy {
  font-size: 18px;
  line-height: 1.4;
  font-style: normal;
}

.fine-print {
  font-size: @fine-print-size;
  line-height: @fine-print-line-height;
  letter-spacing: @fine-print-letter-spacing;
}

ul, ol {
  margin-bottom: 0;
  padding-left: .8rem;
  margin-top: 1.3rem;
  &:first-child {
    margin-top: 0;
  }
  li {
    .p();

    ~ li {
      margin-top: .5rem;
    }
  }
}

ul {
  list-style: none;
  position: relative;
  li {
    &:before {
      content: '\2022';
      font-size: 1.1rem;
      float: left;
      position: absolute;
      left: 0;
    }
  }
}
.unbullet {
  list-style: none;
  padding: 0;
  li {
    margin: 0;
    &:before { content: none; }
  }
}

.bullet-before(@margin: .4rem, @color: inherit) {
  &:before {
    content: '\2022';
    color: @color;
    margin-right: @margin;
    display: inline;
    float: none;
    font-size: inherit;
    position: static;
  }
}
.bullet-after(@margin: .4rem, @color: inherit) {
  &:after {
    content: '\2022';
    color: @color;
    margin-left: @margin;
    display: inline;
    float: none;
    font-size: inherit;
    position: static;
  }
}

.pipe-before(@color: inherit) {
  &:before {
    content: "\00a0|\00a0";
    color: @color;
  }
}
.pipe-after(@color: inherit) {
  &:after {
    content: "\00a0|\00a0";
    color: @color;
  }
}

.footer-list-item {
  font-size: @base-font-size;
  font-weight: 400;
  display: block;
  .font-smoothing();
  padding: .59rem 0;
  line-height: 1.6;
  .resp(@mobilemax, {
    padding: 0;
  });
  a[href*='tel'] {
    margin-right: .25rem;
  }

  a {
    &:hover {
      color: @footer-second-link-hover-color;
      text-decoration: underline;
    }
    &.active {
      color: @link-active-color;
      text-decoration: underline;
    }
  }
}

.aside-list(@item: ~"li") {
  .unbullet;
  font-size: @base-font-size;

  @{item} ~ @{item} {
    margin-top: 1.45rem;
    margin-top: 1.25rem; // See: /ex-about/example-generic-page
  }

  @{item} a {
    .aside-list-link();
    vertical-align: middle;
  }
}

.aside-list-link {
  .menu-item-link();
  font-size: @mobile-base-font-size;
  line-height: @h4-line-height;
  font-weight: 400;
  color: @link-color;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  margin-right: .5rem;

  .resp(@tabletmax, {
    width: auto;
  });
}


.field-files,
.field-links,
.field-related {
  .aside-list();
}

.field-file {
  a {
    .aside-list-link();
  }
}

.file-size {
  display: inline-block;
  vertical-align: middle;
  color: @medium-text-color;
  font-weight: 600;
}

/* --- [ Inline Icons ] --------------- */

.file {
  display: block;
  .icon-inline-after(@download-file--gray, 1em);
  &:after {
    top: .8rem;
    width: 1.4rem;
    height: 1.4rem;
  }
}
.field-links {
  a {
    display: block;
    .icon-inline-after(@link-external--gray, 1em);
    &:after {
      top: .8rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
}
.field-related {
  a {
    display: block;
    .icon-inline-after(@link--gray, 1em);
    &:after {
      top: .8rem;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
}

.field-email {
  a {
    display: block;
    color: @faint-text-color;
    .icon-before(@mail--gray, 1.4rem);

    &:hover {
      color: @link-color;
      .icon-before(@mail--blue, 1.4rem);
    }
  }
}

.field-phone {
  a {
    display: block;
    color: @faint-text-color;
    .icon-before(@phone--gray, 1.4rem);

    &:hover {
      color: @link-color;
      .icon-before(@phone--blue, 1.4rem);
    }
  }
}

.field-live-chat-link {
    a {
    display: block;
    color: @faint-text-color;
    .icon-before(@speech-bubble-dots--gray, 1.4rem);

    &:hover {
      color: @link-color;
      .icon-before(@speech-bubble-dots--blue, 1.4rem);
    }
  }
}


blockquote,
.field-quote {
  .h3;
  margin: 0;
  color: @medium-text-color;
  line-height: 1.364;
  font-style: normal;
  font-weight: normal;
}
.blockquote,
.pull-quote-inner {
  &:before { content: '\201c'; }
  &:after { content: '\201d'; }
}

.overview-text {
  font-size: 1.4rem;
  line-height: 1.4;
  margin: 0;
  margin-bottom: 30px;
  font-style: italic;
  letter-spacing: -0.02rem;
}


.meta {
  font-family: @base-font;
  font-size: @caption-font-size;
  font-weight: 600;
  line-height: 1.5;
  color: @medium-text-color;
  text-transform: uppercase;
  letter-spacing: .025em;
  position: relative;

  .field-contents {
    display: inline;
  }

  a {
    text-decoration: none;
    color: @link-color;
    font-weight: 700;
    .font-smoothing();

    &:hover {
      color: @link-active-color;
    }
  }

  &.field-date {
    [class*="date-display"] {
      display: inline;
    }
  }
}
.meta--light {
  .meta();
  font-weight: 400;
  color: @faint-text-color;
}
.meta--related {
  .meta();
}


.field-post-date {
  .meta--light();
}

.meta--big {
  .meta();
  font-size: @h4-size;
}

.meta--type,
.breadcrumb {
  .meta();
  .zone-preface & {
    &:first-child {
      margin: 0;
      margin-top: -1.3rem;

      .resp(@tabletmax, { margin-top: 0; });
    }
  }
}

.byline {
  margin-top: .3rem;
}


.group-meta {
  // accomondates span lists and uls and ols
  .meta();
  padding: 0;
  list-style: none;
  > * {
    display: inline-block;
    position: relative;
    margin: 0;
    margin-right: .4rem;
    &:before { content: none; }

    &:first-child:not(:last-child) {
      .bullet-after(.4rem);
      ~ *:not(:last-child) {
        .bullet-after(.4rem);
      }
    }
  }

}

.field-resource-types,
.field-topics {
  // See: /research/databases
  // See: /example-blog-post-lorem-ipsum-dolor
  .view-mode-full > .field-body ~ & {
    margin-top: 2.35rem;
  }
  > .field-contents {
    .group-meta();
  }
}

.group-header > h3 {
  .block-title();
}

.banner-label {}
.teaser-label {}
.banner-image .meta {}


table {
  max-width: 100%;
  &[style*="width"] {
    width: auto !important;
    max-width: 100% !important;
  }
}


// Inline Icons
// ================================

.icon {
  &.phone { .icon-before(@phone); }
  &.mail { .icon-before(@mail); }
  &.addy { .icon-before(@map-pin); }
}

.field-recurring-date,
.group-event-details > .field-date {
  .icon-before(@calendar--white);
}


.field-website { .icon-before(@globe--white); }
.field-facebook { .icon-before(@facebook-reversed); }
.field-twitter { .icon-before(@twitterbird); }

.user-menu {
  .menu-title-live-chat > a .menu-item-inner {
    .resp-min(@tabletmax + 1, {
      .icon-inline-after(@speech-bubble-dots--aqua; 1.05rem);
    });
    .resp(@tabletmax, {
      .icon-inline-after(@speech-bubble-dots--white);
    });
  }
  .menu-title-cart > a .menu-item-inner {
    &[data-badge] {
      &:before {
        content: attr(data-badge);
        .abs(5px,-7px,auto,auto);
        z-index: 48;
        background: @brand-primary;
        color: @white;
        border-radius: 2000px;
        width: 16px;
        height: 16px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11.5px;
        letter-spacing: 0;
      }
    }

    .resp-min(@tabletmax + 1, {
      .icon-inline-after(@cart--blue; 1.05rem);
    });
    .resp(@tabletmax, {
      .icon-inline-after(@cart--white);
    });
  }
}



// Links
// ================================

.link() {
  color: @link-color;
  text-decoration: none;
  .trans;
  .font-smoothing(subpixel-antialiased);

  &:hover {
    color: @link-hover-color;
    text-decoration: underline;
  }

  &.active {
    color: @link-active-color;
    text-decoration: underline;
  }
}

a {
  .link();
}

.menu-title-live-chat {
  > span, a {
    .link();
  }
}
.menu-title-live-chat > span {
  cursor: pointer;
}
.a--no-underline() {
  .text {
    border-bottom: none;
  }
}

.action-link(@display: block) {
  font-size: @base-font-size;
  font-weight: 600;
  letter-spacing: .05em;
  text-transform: uppercase;
  color: @link-color;
  display: @display;
  .font-smoothing(antialiased);

  &:hover {
    text-decoration: underline;
  }
  &.active {
    text-decoration: underline;
    color: @link-active-color;
  }
}

.more-link {
  font-size: @base-font-size;
  letter-spacing: .05em;
  text-transform: capitalize;
}

.top-menu-link {
  color: @white;
  font-family: @display-font;
  text-decoration: none;
  font-size: @base-font-size;
  line-height: 1.8;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  max-height: 100%;
  .font-smoothing(subpixel-antialiased);

  .resp-range(@mobilemax + 1, @tabletmax, {
    font-size: @base-font-size;
    padding: .5rem 1rem;
  });

  .menu-item-inner {
    position: relative;
  }


  &:hover {
    text-decoration: none;
  }

  &.active,
  &.active-trail:not([href*="logout"]) {

  }
}

.mobile-top-menu-link {
  .top-menu-link();
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  padding: 0;
  padding: .5rem 0 .55rem;
  .menu-item-inner:before { content: none; }
}

.mobile-quick-menu-link {
  color: @white;
  font-family: @display-font;
  font-size: @fine-print-size;
  font-weight: 600;
  line-height: 1.125;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  .font-smoothing(subpixel-antialiased);
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  padding: 10px 14px;
  display: block;
  .menu-item-inner:before { content: none; }
}

.menu-item-link {
  color: @white;
  font-family: @display-font;
  font-size: @h4-size;
  font-weight: normal;
  line-height: @h4-line-height;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    cursor: default;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
}

.subnav-item-link() {
  display: block;
  color: @button-color;
  font-family: @display-font;
  font-size: @h5-size;
  line-height: @h5-line-height;
  font-weight: 200;
  text-decoration: none;

  &:hover {
    color: @brand-alt;
  }

  &.active {
    cursor: default;
    color: @brand-primary;
    font-weight: 400;
    .font-smoothing(subpixel-antialiased);
    &:hover {
      color: @brand-alt;
    }
  }
}

.fine-menu-link(@color:@white) {
  display: block;
  color: @color;
  text-transform: uppercase;
  letter-spacing: 0.09rem;
  font-size: @fine-print-size;
  line-height: 1.8;
  font-weight: normal;
  padding: .59rem 0 .565rem;
  position: relative;

  // tap target
  &:before {
    content: ' ';
    .abs(0,-1.125rem,0,-1.125rem);
  }
  &:after {
    content: ' ';
    color: inherit;
    .abs(0,auto,auto,0);
    width: 100%;
    border-top: 5px solid;
    border-color: inherit;
    opacity: 0;
    .trans(opacity);
  }

  &.active,
  &.active-trail:not([href*="logout"]),
  &:hover {
    text-decoration: none;
    &:after {
      opacity: 1;
    }
  }

}

.link-tray {
  .h4-aside();
  margin-top: 2.925rem;
  a {
    .action-link(@display: inline-block);
  }
  .separator {
    margin: 0 .5rem; /// SEE SR
  }

  .resp(@tabletmax, {
    text-align: center;
  });
  .resp(@mobilemax, {
    margin-top: 2.05rem;
    .separator { display: none; }
    a {
      display: block;
      ~ a { margin-top: 1.6rem; }
    }
  });
}

a#main-content {
  .element-invisible;
}

div.contextual-links-wrapper {
  .abs(auto,auto,auto,auto);
  a {
    display: none;
    border: none;
    padding: none;
    &.contextual-links-trigger-active {
      display: block;
      margin-left: -1.5rem;
    }
  }
  ul.contextual-links {
    .abs(0,auto,auto,0);
    z-index: 100;
  }
}


// File
// ================================

.field-file-attachments {
  list-style: none;
  padding: 0;
  margin: 0;
}
.file {
  img { display: none; }
}


// Address / Location
// ================================
.field-address {
  .meta();
}
.field-location {
  .street-block{
    .thoroughfare,
    .premise {
      display: inline;
    }
    .premise:before {
      content: ',\0020';
    }
  }
  .country {
    display: none;
  }
}




// Buttons
// ================================

.button-shadow(@color: @black) {
  .trans;
  box-shadow: 0 5px 0 @color;
  &:hover {
    box-shadow: 0 3px 0 @color;
    transform: translateY(2px);
  }
  &:active {
    .trans(none);
    box-shadow: none;
    transform: translateY(5px);
  }
}
.button-highlight(@bg: lighten(@black, 5%), @border: @bg ) {
  &:hover {
    background-color: @bg;
    border-color: @border;
  }
}

.button {
  display: inline-block;
  margin: 0 auto;
  font-family: @heading-font;
  font-size: @h5-size;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 0.45rem 1em;
  text-decoration: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 0;
  appearance: none;
  opacity: 1;
  border: none;
  user-select: none;
  .trans(color);
  .trans(background-color);
  .trans(border-color);
  background-color: transparent;
  font-weight: bold;
  .font-smoothing(antialiased);
  color: @button-color;
  border: 4px solid @button-color;

  &:hover {
    color: @button-active-text-color;
    background-color: @button-active;
    border-color: @button-active;
    text-decoration: none;
  }
  &:active {
    color: @button-highlight-text-color;
    background-color: @button-highlight;
    border-color: @button-highlight;
    transition: none;
  }

  &:focus {
    outline: none;
  }

  &.admin,
  &.admin-extra {
    border: none;
    font-size: 12px;
    padding: 6px;
  }
}
.button--big,
.button--small,
.button-alt,
.button-cta,
.button-dim,
.button-disabled {
  &:extend(.button);
  &:hover {
    &:extend(.button:hover);
  }
  &:active {
    &:extend(.button:active);
  }
  &:focus {
    &:extend(.button:focus);
  }
}

.button--big {
  padding: 1rem 1.875rem;
}
.button--small {
  font-size: 85%;
  padding: 6px 9px 5px;
}

.button-alt {
  background-color: transparent;
  border-color: @faint-bg;
}
.button-alt--big {
  .button--big();
  .button-alt();
}
.button-alt--small {
  .button--small();
  .button-alt();
}

.button-cta {
  color: @white;
  text-shadow: none;
  background-color: @brand-secondary;
  border-color: @brand-secondary;

  &:hover {
    color: @white;
    background-color: @brand-primary;
    border-color: @brand-primary;
  }
}
.button-cta--big {
  .button--big();
  .button-cta();
}
.button-cta--small {
  .button--small();
  .button-cta();
}

.button-dim {
  color: @brand-dim;
  text-shadow: none;
  background-color: transparent;
  border-color: @brand-dim;
  border-width: 3px;
}

.button-dim--big {
  .button--big();
  .button-dim();
}
.button-dim--big {
  .button--small();
  .button-dim();
}

.button-disabled {
  color: @brand-dim;
  text-shadow: none;
  background-color: transparent;
  border-color: @brand-dim;
  pointer-events: none;
  border-width: 3px;
  &:hover {
    color: @brand-dim;
    text-shadow: none;
    background-color: transparent;
    border-color: @brand-dim;
    cursor: default;
  }
}

.button-disabled-big {
  .button--big();
  .button-disabled();
}
.button-disabled-big {
  .button--small();
  .button-disabled();
}

.button--back {
  .icon-bg(@arrow-left--navy; 1rem);
  &:hover { .icon-bg(@arrow-left--violet; 1rem); }
  &:active { .icon-bg(@arrow-left--red; 1rem); }
}

.button-tray() {
  margin-top: 2rem;
  a {
    .button();
  }
}

.page-node-done {
  .links {
    .button-tray();
  }
}

.region-sidebar-first-inner .button {
  .button;
}





.view .more-link,
.region-sidebar-second .view-footer /* See: /search/google/education */ {
  margin-top: 40px;

  a {
    .button-cta();
    padding: 9px 18px 8px;
    font-size: 16px;
    text-transform: none;
    letter-spacing: .2px;
  }
}



button,
input[type="reset"],
input[type="submit"],
input[type="button"],
.form-submit,
input.button,
a.button,
div.button > a,
span.button > a {
  .button;
}



// Images
// ================================
img {
  display: block;
  max-width: 100%;
  height: auto;
}
.image-style-circle_mask {
  border-radius: 2000px;
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
}
.field-body img {
  height: auto !important;
  margin: 36px 0;

  &[style*="float"] {
    margin-top: 0;
    &[style*="left"] {
      margin-right: 1.4rem;
    }
    &[style*="right"] {
      margin-left: 1.4rem;
    }
  }
}
.field-banner-image img {
  width: 100%;
}

.block-landing-page-banner-image .field-trans-image { display: none; }

.bg-image {
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &:hover {
    opacity: 1;
    .image__inner:after {
      opacity: .125;
    }
  }

  img { display: none; }

  &.placeholder {
    background: fade(@black, 60%);
    &.image-style-square {
      background: fade(@black, 80%);
    }
  }

  .image__inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    > * {
      position: relative;
      z-index: 1;
    }

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: transparent;
      z-index: 0;
      opacity: 1;
      .trans;
    }
  }

  &.image-style-full {
    padding-bottom: 66.667%;

    .image__inner {
      padding: 30px;
      color: @white;
      letter-spacing: -1em;

      > * {
        letter-spacing: normal;
      }

      &:before {
        content: ' ';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        width: 1px;
        background: transparent !important;
        margin-left: -1px;
      }
      &:after {
        background-color: fade(@brand-secondary, 50%);
      }
      .teaser-label {
        position: absolute;
        top: 0;
        left: 0;
        background: @brand-primary;
        color: @white;
        line-height: 1;
        padding: 10px 10px 4px;
      }

      .group-titling {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        margin-top: 40px;

        h2 {
          line-height: 1;
          margin: 0;
          margin-bottom: 4px;
          .resp(@mobilemax, {
            line-height: 1.2;
          });
        }

        .field-name-field-subtitle {
          font-size: 18px;

          .resp(@mobilemax, {
            line-height: 1.4;
          });
        }
      }

      .meta {
        position: absolute;
        bottom: 19px;
        left: 30px;
        right: 30px;
        top: auto;
      }
    }
  }
  &.image-style-teaser {
    padding-bottom: 55.55%;
  }
  &.image-style-square {
    padding-bottom: 100%;

    .image__inner {
      padding: 30px;
      color: @white;
      letter-spacing: -1em;
      padding: 14px;

      > * {
        letter-spacing: normal;
      }

      &:before {
        content: ' ';
        display: inline-block;
        vertical-align: bottom;
        height: 100%;
        width: 1px;
        background: transparent !important;
        margin-left: -1px;
      }

      .group-titling {
        display: inline-block;
        vertical-align: bottom;
        width: 100%;
        margin-bottom: -8px;
        opacity: 1;
        .trans;
      }

      .group-hover-box {
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px;
        background: fade(@brand-primary, 80%);
        opacity: 0;
        .trans;

        &:before {
          content: ' ';
          display: inline-block;
          vertical-align: middle;
          width: 1px;
          height: 100%;
          margin-left: -1px;
        }

         > * {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    &:hover {
      .image__inner {
        .group-titling { opacity: 0; }
        .group-hover-box { opacity: 1; }
      }
    }
  }
}


// Video
// ================================

.field-signature-video {
  margin-bottom: 1.5rem;
}

.field-body {
  .field-contents > iframe {
    width: 100%;
    max-width: 100%;
    margin-top: 1.6rem;

    &:first-child {
      margin-top: 0;
    }
  }
}


.vid-wrapper,
.embedded-video .player {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;

  .ratio { display: none; }

  iframe {
    .abs-0();
    max-width: 100%;
    height: 100%;
    width: 100%;
  }
}

.embedded-video .ratio { display: none; }


// Admin Related
// ================================

ul.tabs.primary {
  .unbullet();
  padding: 4px;
  margin: 0 auto;
  max-width: 1200px;


  .node-type-page &,
  .node-type-story & {
    text-align: center;
  }

  li {
    display: inline-block;
    padding: 4px;

    > a {
      .button;
      font-size: @base-font-size;
      padding: 6px 9px;

      &:hover {}

      &.active {
        text-decoration:none;
        pointer-events: none;
      }
    }
  }
}

#admin-menu .dropdown  {
  .unbullet();
}
.contextual-links {
  .unbullet();
}
.krumo-node {
  .unbullet();
}



// Field Collection
// ================================

.field-collection-container {
  margin: 0;
  border: none;
}

.field-collection-view {
  margin: 0;
  padding: 0;
}

.field-collection-view-final {
  border: none;
}
