
// Share Links
// ================================

// See: /blog/*

.share-links {
  .unbullet();
  letter-spacing: -1em;
  line-height: 0;
  &, &:first-child {
    margin: -.5rem -.5rem 1.125rem;
  }

  > * {
    letter-spacing: normal;
    line-height: normal;
  }

  > li {
    padding: .5rem;
    display: inline-block;

    > a {
      width: 1.875rem;
      height: 1.875rem;
      display: block;
      position: relative;
      background: transparent;

      &:before,
      &:after {
        content: ' ';
        .abs-0();
        .trans(opacity);
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 1.875rem;
        background-color: transparent;
      }
      &:after {
        opacity: 0;
      }

      &.facebook {
        &:before { background-image: @share-facebook--grey; }
        &:after { background-image: @share-facebook--red; }
      }
      &.mail, &.email {
        &:before { background-image: @share-mail--grey; }
        &:after { background-image: @share-mail--red; }
      }
      &.twitter {
        &:before { background-image: @share-twitter--grey; }
        &:after { background-image: @share-twitter--red; }
      }

      &:hover {
        &:before { opacity: 0; }
        &:after { opacity: 1; }
      }


      .text { .element-invisible; }
    }
  }
}