
// Donation Feature (commerce)
// ================================

.page-donate-now {

  .region-content {
    counter-reset: donation-steps;
    h2 {
      .h3;
      font-weight: bold;
      &:before {
        content: counter(donation-steps, decimal) '. ';
        counter-increment: donation-steps;
      }
    }
  }
}


.crm-core-profile-entry-form {
  // See: /donate-now

  > div {
    letter-spacing: -1em;
    > * { letter-spacing: normal; }
  }

  > div > [class*="field-type"],
  > div >  [class*="form-item"][class*="text"],
  > div > [class*="credit-card-expiration"],
  > div >  [class*="form-item"][class*="select"],
  > [class*="field-"][class*="phone"] {

  }

  > div > [class*="individual-contact-name"],
  > div .field-type-addressfield > [id*="customer-address"] > .street-block,
  > div .field-type-addressfield > [id*="customer-address"] > [class*="form-item"],
  > div .field-type-addressfield > [id*="customer-address"] > [class*="addressfield-container"] > [class*="form-item"],
  > div >  [class*="form-item"][class*="credit-card"]  {

  }

  > div > [class*="field-type"][class*="addressfield"] {

  }

  h2 {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid @hr-color;
    display: block;
    &:first-child { margin-top: 0; }
  }

  [id*="customer-address"] > label:first-child {
    display: block;
    font-style: italic;
    font-weight: normal;
  }

  .amounts {
    letter-spacing: -1em;
    margin: 0 -4px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > * {
      display: inline-block;
      vertical-align: middle;
      width: 16.666%;
      min-width: 100px;

      //width: auto;
      //min-width: 100px;
      flex: 1;

      .resp(@mobilemax, {
        flex-basis: 50%;
      });
    }
    > select {
      .element-invisible;
    }

    input[type="button"] {
      padding: 10px;
      display: inline-block;
      box-sizing: border-box;
      border-style: solid;
      border-color: transparent !important;
      border-width: 4px;
      background-clip: padding-box;
      color: @white !important;
      background-color: @button-color;
      box-shadow: none !important;

      &:focus {
        transform: none;
        outline: none;
      }

      &.selected {
        background-color: @navy;
        color: @white !important;
      }
    }

    #donation-other-amount {
      display: inline-block;
      box-sizing: border-box;
      box-shadow: none;
      border: 4px solid transparent;
      background-clip: padding-box;
      background-repeat: no-repeat !important;
      background-position: 12px center !important;
      background-image: @dollar-sign--brightblue !important;
      background-size: 1em 1em;
      padding-left: 30px;
      outline: 1px solid @input-border-color;
      outline-offset: -5px;
    }
  }


  // Designation
  // -----------
  .field-name-field-cmcd-designation-honor-mem,
  .field-name-field-cmcd-designation-honoree,
  .field-name-field-cmcd-designation-notify,
  .field-name-field-cmcd-designation-email {
    &[style*="block"] {
      .__half-width() !important;

      .resp(@mobilemax, {
        width: 100% !important;
      });
    }
  }
      // Left
  .field-name-field-cmcd-designation-honor-mem,
  .field-name-field-cmcd-designation-notify {
    .__p-right();
    .resp(@mobilemax, { padding-right: 0; });
  }
      // Right
  .field-name-field-cmcd-designation-honoree,
  .field-name-field-cmcd-designation-email {
    .__p-left();
    .resp(@mobilemax, { padding-left: 0; });
  }



  // Your Information
  // -----------

  .form-item-crm-core-contact-individual-contact-name-given,
  .form-item-crm-core-contact-individual-contact-name-family {
    .__half-width() !important;

    .resp(@mobilemax, {
      width: 100% !important;
    });
  }
      // Left
  .form-item-crm-core-contact-individual-contact-name-given {
    .__p-right();
    .resp(@mobilemax, { padding-right: 0; });
  }
      // Right
  .form-item-crm-core-contact-individual-contact-name-family {
    .__p-left();
    .resp(@mobilemax, { padding-left: 0; });
  }


  // Partner/Spouse
  // -----------

  .form-wrapper[class*="partner"][class*="fname"],
  .form-wrapper[class*="partner"][class*="lname"] {
    &[style*="block"] {
      .__half-width() !important;

      .resp(@mobilemax, {
        width: 100% !important;
      });
    }
  }
      // Left
  .form-wrapper[class*="partner"][class*="fname"] {
    .__p-right();
    .resp(@mobilemax, { padding-right: 0; });
  }
      // Right
  .form-wrapper[class*="partner"][class*="lname"] {
    .__p-left();
    .resp(@mobilemax, { padding-left: 0; });
  }


  // Address
  // -----------

  .field-name-commerce-customer-address {
    margin-top: 30px;
  }

  .street-block {
    letter-spacing: -1em;
    > * { letter-spacing: normal; }

    .form-item[class*="customer-address"][class*="thoroughfare"],
    .form-item[class*="customer-address"][class*="premise"] {
      .__half-width() !important;

      .resp(@mobilemax, {
        width: 100% !important;
      });
    }

    // Left
    .form-item[class*="customer-address"][class*="thoroughfare"] {
      .__p-right();
      .resp(@mobilemax, { padding-right: 0; });
    }
    // Right
    .form-item[class*="customer-address"][class*="premise"] {
      .__p-left();
      .resp(@mobilemax, { padding-left: 0; });
    }
  }

  .locality-block {
    letter-spacing: -1em;
    > * { letter-spacing: normal; }

    .form-item[class*="customer-address"][class*="locality"],
    .form-item[class*="customer-address"][class*="administrative-area"],
    .form-item[class*="customer-address"][class*="postal-code"] {
      .__half-width() !important;

      .resp(@mobilemax, {
        width: 100% !important;
      });
    }
        // Left
    .form-item[class*="customer-address"][class*="locality"],
    .form-item[class*="customer-address"][class*="postal-code"] {
      .__p-right();
      .resp(@mobilemax, { padding-right: 0; });
    }
        // Right
    .form-item[class*="customer-address"][class*="administrative-area"] {
      .__p-left();
      .resp(@mobilemax, { padding-left: 0; });
    }
  }



  // Credit card
  // -----------

  #payment-details {

    letter-spacing: -1em;
    > * { letter-spacing: normal; }

    .form-item[class*="payment-details"][class*="credit-card-type"],
    .form-item[class*="payment-details"][class*="credit-card-number"],
    .commerce-credit-card-expiration,
    .form-item[class*="payment-details"][class*="credit-card-code"] {
      .__half-width() !important;

      .resp(@mobilemax, {
        width: 100% !important;
      });
    }

        // Left
    .form-item[class*="payment-details"][class*="credit-card-type"],
    .commerce-credit-card-expiration {
      .__p-right();
      .resp(@mobilemax, { padding-right: 0; });
    }
        // Right
    .form-item[class*="payment-details"][class*="credit-card-number"],
    .form-item[class*="payment-details"][class*="credit-card-code"] {
      .__p-left();
      .resp(@mobilemax, { padding-left: 0; });
    }

  }

  .commerce-credit-card-expiration {
    padding-top: 20px;
    position: relative;

    > .form-item,
    > .commerce-credit-card-expiration {
      .__m-top;
    }

    .form-type-select,
    .form-type-textfield {
      .__auto-width;
      vertical-align: bottom;
    }

    .commerce-month-year-divider {
      color: transparent;
      text-indent: -9999px;
      height: 100%;
      display: inline-block;
      padding: 9px 4px 8px;
      position: relative;
      margin: 0 8px 0 6px;

      &:after {
        content: ' ';
        position: absolute;
        top: 10%;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%) rotate(15deg);
        width: 2px;
        background-color: @input-border-color;
      }
    }

    .form-item[class*="credit-card-type"] {
      .__p-right;
    }
    .form-item[class*="credit-card-number"] {
      .__p-left;
    }
    .form-item[class*="credit-card-exp-month"],
    .form-item[class*="credit-card-code"] {
      white-space: nowrap;
      margin-bottom: 0;

      label {
        margin-right: 8px;
        position: absolute;
        top: 0;
        left: 0;
      }
      label,
      select,
      .form-text {
        display: inline-block;
        vertical-align: middle;
        width: auto;
      }
    }
    .form-item[class*="credit-card-exp-year"] {
      margin-bottom: 0;
    }
    > .commerce-credit-card-expiration {
      display: inline-block;
      margin-top: 24px;
      .__p-right;
    }
    .form-item[class*="credit-card-code"] {
      .__p-left;
    }

  }

  .form-actions {

    input[type="submit"] {
      .button--big;
      display: block;
      width: 100%;
      margin-top: 50px;
      .resp(@mobilemax, {
        margin-right: 0;
        display: block;
        width: 100%;
      });
    }
  }
}
