
.menu-block-wrapper {
  // Top Level Nav
  .top-level-nav &,
  .mobile-top-level-nav & {
    z-index: 30;

    .menu{
      .unbullet();
      position: static;
    }

    .menu-title-layout-group > .nolink {
      display: none !important;
    }

    .menu-title-search {
      .mainSearchToggleProxy {
        background-image: @search--white !important;
        background-repeat: no-repeat;
        background-position: 100% 50%;
        background-size: 18px 18px;
        background-color: transparent !important;
      }
      .menu-item-inner {
        white-space: nowrap;
        text-indent: -9999px;
        overflow: hidden;
      }
      .resp(@tabletmax, {
        display: none !important;
      });
    }

    .resp(@tabletmax, {
      text-align: left;
    });

    a,
    .nolink {
      display: block;
      .menu-item-inner {
        display: block;
      }
    }

    > .menu {
      > li {

        &,
        > .tlAccSwitchWrap {
          > a,
          > .nolink {
            .top-menu-link;

            .resp-range(@mobilemax + 1,  @tabletmax, {
              .mobile-top-menu-link();
              padding: 0.9rem 1.65rem 0.8rem;
              padding-right: 1rem; // 16px
              flex: 0 1 auto;
              width: auto;
            });

            .resp(@mobilemax, {
              .mobile-top-menu-link();
            });
          }
        }

        > .tlAccSwitchWrap {
          > a,
          > .nolink {
            .top-menu-link;
            .resp-min(@tabletmax + 1, {
              padding-left: 1.6rem;
              padding-right: 1rem;
            });
          }
        }
      }
    }

    .menu .menu {
      text-align: justify;
      letter-spacing: -1em;
      line-height: 0;

      > * {
        letter-spacing: normal;
        text-align: left;
        line-height: @h6-line-height;
      }
      &:after {
        content: ' ';
        display: inline-block;
        width: 100%;

        .resp(@tabletmax, {
          content: none;
        });
      }
      a,
      .nolink {
        .menu-item-link();

      }

      .menu-title {
        .icon(@icon) {
          .menu-item-inner {
            position: relative;
            padding-left: 50px;
            padding-right: 0;
            .resp(@tabletmax, {
              padding-left: 36px;
              padding-right: 0;
            });

            &:before {
              content: ' ';
              position: absolute;
              width: 50px;
              height: 32px;
              top: 50%;
              left: 0;
              .t-y(-50%);
              background-image: @icon;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 24px;

              .resp(@tabletmax, {
                width: 36px;
                background-position: left center;
              });
            }
          }
        }
        // // Ex:
        // &-intensive-programs {
        //   .icon(@chat--white);
        // }
      }
    }

    .menu .menu .menu {
      > li {

        > a,
        > .nolink {
          .menu-item-link;

          .menu-item-inner {
            position: relative;

            &:after {
              content: ' ';
              position: absolute;
              bottom: -.75em;
              left: 0;
              right: 0;
            }
            .resp(@tabletmax, {
              &:after {
                content: none;
              }
            });
          }
          .resp-min(@mobilemax + 1, {
            padding: 12px 28px 9px;
          });
          .resp(@mobilemax, {
            padding: 12px;
          });
        }

        &:last-child {
          > a,
          > .nolink {
            .menu-item-inner:after {
              content: none;
            }
          }
        }
        &:first-child:last-child {
          > a,
          > .nolink {
            .menu-item-inner:after {
              content: ' ';
              .resp(@tabletmax, { content: none; });
            }
          }
        }

      }
    }

    .tlAccSwitchWrap,
    .slAccSwitchWrap {
      display: flex;
      flex-flow: row;

      > * {
        width: 100%;
      }
      > a {
        flex-shrink: 1;
      }
    }

    .tlAccSwitch,
    .slAccSwitch {
      display: none;
      position: relative;
      width: 39px;
      padding: 0;
      .resp(@tabletmax, {
        width: 42px;
      });
      .resp(@mobilemax, {
        width: 1rem;
      });
      &:before {
        content: ' ';

        .abs-0();
        width: auto;

        .resp(@mobilemax, {
          .abs(0, auto, 0, -50%);
          width: 200%;
        });
      }
      .text {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;/*rtl:ignore*/
        width: 100%;
        height: 100%;
        border-radius: 2000px;
        text-indent: -9999px;
        white-space: nowrap;
        overflow: hidden;
        pointer-events: none;
        background-color: transparent;
        background-image: @dropdown-arrow--white;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 14px;
        transform-origin: center;
        .t-x(-50%);
        .t-y(-50%);
        .t-scale3d(~"1,1,1");
        transition: transform 150ms ease 0ms, background-color 150ms ease 0ms;

        .resp(@mobilemax, {
          background-position: center;
        });
      }

      &:hover .text {
        background-color: transparent;
      }

      &.isOpen {
        .resp-min(@tabletmax + 1, { background-color: transparent; });
        .resp(@tabletmax, {
          .text {
            .t-x(-50%);
            .t-y(-50%);
            .t-scale3d(~"1,-1,1");
            background-color: transparent;
          }
        });
      }
    }

    .tlAccSwitch {
      display: block;
    }
    .slAccSwitch {
      .resp(@mobilemax, {
        display: block;
        padding: 0;
        display: none;
      });
    }

    > .menu {
      .IB-gapfree();
      text-align: right;
      line-height: 0;
      font-size: 0;

      > * {
        line-height: normal;
        font-size: @base-font-size;
      }

      > li:first-child {
        border-top: none;
      }
      > li {
        .resp(@tabletmax, {
          display: block;
          position: static;
        });

        .resp(@mobilemax, {
          border: none;
        });
        > a, > .nolink,
        > .tlAccSwitchWrap > a {
          position: relative;
        }


        &.expanded {
          > a, > .nolink,
          > .tlAccSwitchWrap > a,
          > .tlAccSwitchWrap > .nolink {
          }
        }
        .resp(@tabletmax, {
          > a, > .nolink,
          > .tlAccSwitchWrap > a,
          > .tlAccSwitchWrap > .nolink {
            &:before {
              content: none;
            }
          }
          &.expanded {
            > a, > .nolink,
            > .tlAccSwitchWrap > a,
            > .tlAccSwitchWrap > .nolink {
              &:after {
                content: none;
              }
            }
          }
        });

        &:hover {
          background-color: @menu-main-bg-color;
          > a, > .nolink,
          > .tlAccSwitchWrap > a,
          > .tlAccSwitchWrap > .nolink {

            &.active {

            }
          }
        }
      }

      .resp(@tabletmax, {
        text-align: inherit;
        line-height: normal;
        display: block;
        position: static;
        width: 100%;

        > * {
          display: block;
        }
      });

      > li > .menu {
        position: absolute;
        top: 100%;
        bottom: auto;
        overflow: hidden;
        transform-origin: top center;
        z-index: 30;

        .resp(@tabletmax, {
          position: static;
          height: auto;
          min-width: 0;
          background-color: @mobile-menu-main-bg-color;

          > li {
            &:first-child {
              border-top: none;
              padding-top: 0;
            }
          }

          &.isClosed {
            height: 0 !important;
          }
        });
        .resp(@mobilemax, {
          > li { border: none; }
        });

        .menu {
          height: auto;
          max-height: none;
          opacity: 1;
          position: static;
          transform: none;

          &.slAccordion {
            overflow: hidden;
            .resp(@mobilemax, {
              display: none;
            });
          }
        }
      }

      > .expanded {
        position: static;
        .trans(background-color);

        &.tlAccOpen {
          background-color: @brand-primary;
          .resp-min(@tabletmax + 1, {
            background-color: transparent;
          });
        }

        > .menu {
          left: 0;
          right: 0;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
          padding: 1.125rem 75px 2.5rem;

          .resp-min(@tabletmax + 1, {
            max-height: 0;
            padding: 0;
            overflow: visible;
          });

          .resp(@tabletmax, {
            padding: 1.125rem 0 1.35rem;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
          });

          .resp-range(@mobilemax + 1, @tabletmax, {
            padding: 1.125rem 40px 2.65rem;
            display: block;
          });

          > li {
            max-width: 265px;
            overflow: hidden;
            .resp(@tabletmax, {
              width: 50%;
              max-width: 50%;
            });
            .resp-min(@mobilemax + 1, {
              > .menu {
                position: relative;
                &:before {
                  content: ' ';
                  width: 1px;
                  background-color: white;
                  .abs(14px,auto, 11px, 0);
                }
              }
            });
            .resp-range(@mobilemax + 1, @tabletmax, {
              > .menu {
                margin-top: 8px;
                position: relative;
              }
              &:nth-child(n + 3) { margin-top: 50px; }
              &:nth-child(odd) {
                float: left;
                clear: left;
              }
              &:nth-child(even) {
                float: right;
                clear: right;
              }
            });
            .resp(@mobilemax, {
              padding-left: 2rem;
              padding-top: .8rem;
              border-left: 2px solid @white;
              width: 100%;
              max-width: 100%;
            });
          }
        }

        &:hover {
          .resp-min(@tabletmax, {
            &.tlAccOpen {
              background-color: @brand-primary;
            }
            > .menu {
              max-height: 4000px;
              padding: 1.125rem 75px 2.5rem;
              overflow: visible;
            }
          });
        }
      }

    }

    > .menu > li {

      &.mobile-only {
        display: none;
        .resp(@tabletmax, {
          display: block;
        });
      }

      > a,
      > .nolink {
        display: block;
      }
      > .menu {
        background-color: transparent;
        background-clip: padding-box;
        padding: 1.75em 0 1.5em;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        overflow: visible;
        .resp-min(@tabletmax + 1 {
          padding: 0;
          max-height: 0;
          overflow: visible;
          opacity: 0;
          transform-origin: top center;
          transform: scale3d(1, 0, 1);
          .trans(max-height, 0ms, ease, 500ms);
          .trans(padding, 0ms, ease, 500ms);
          .trans(transform, 400ms, ease, 400ms);
          .trans(opacity, 300ms, ease, 300ms);
        });

        &:before {
          content: ' ';
          .abs(0, -100%, 0, -100%);
          background-color: @menu-main-bg-color;
          z-index: -1;

          .front &,
          .header_banner & {
            background-color: @menu-main-bg-color;
          }

          .resp(@tabletmax, {
            content: none;
          });
        }

        .resp-range(@mobilemax + 1, @tabletmax, {
          padding: 0;
          border-top: none;
          box-shadow: none;
          transform: none;
          max-height: 4000px;
          opacity: 1;
          overflow: hidden;
          .abs(100%, 0, auto, 0);
          background-color: @brand-primary;
        });
        .resp(@mobilemax, {
          padding: 0;
          border-top: none;
          box-shadow: none;
          transform: none;
          max-height: 4000px;
          opacity: 1;
          overflow: hidden;
          background-color: @brand-primary;
        });

        > li {
          > a,
          > .tlAccSwitchWrap > a,
          > .slAccSwitchWrap > a,
          > .nolink,
          > .tlAccSwitchWrap > .nolink,
          > .slAccSwitchWrap > .nolink  {
            .resp-min(@mobilemax + 1, {
              .mobile-top-menu-link();
              padding: 12px 28px;
            });
          }
        }
      }
      &:hover > .menu {
        .resp-min(@tabletmax + 1 {
          max-height: 4000px;
          padding: 1.75em 0 1.5em;
          opacity: 1;
          transform: scale3d(1, 1, 1);
          .trans(max-height, 0ms, ease, 200ms);
          .trans(padding, 0ms, ease, 200ms);
          .trans(transform, 300ms, ease, 300ms);
          .trans(opacity, 200ms, ease, 300ms);
        });
        .resp(@tabletmax, {
          border-top: none;
          box-shadow: none;
          transform: none;
          opacity: 1;
        });
      }

      > .menu {
         li {
          > a,
          > .nolink {

            .menu-item-inner {
              position: relative;
            }
          }
        }
      }
    }

  } // /* END .top-level-nav .menu-block-wrapper */

} // /* END .menu-block-wrapper */





