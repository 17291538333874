#logo {
  padding: .9375rem 0 1.25rem;
  .resp(@tabletmax, {
    height: 100%;
    width: 100%;
    padding: .9375rem 0;
  });
  .resp(@mobilemax, { padding: 0; });
  a {
    display: block;
    background-image: none;
    background-repeat: no-repeat;
    background-size: contain;
    .resp-range(@mobilemax + 1, @tabletmax, {
      height: 61px;
      display: flex;
    });
  }
  img {
    width: auto;
    max-width: 100%;
    //Max-height 85px for Anniv logo, swap back to 60px if changing to old logo:
    max-height: 85px;
    opacity: 1;
    .resp(@tabletmax, {
      max-height: none;
      width: 100%;
      height: 100%;
    });
    .resp(@mobilemax, { height: 36px; });
  }
  // If client wants to return to original logo,
  // replace JLL_Logo_Anniv.svg with JLL_Logo_White_Mobile.svg for both tablet+mobile:
  .resp-range(@tabletmax + 1, 1200px, {
    a {
      background-image: url('../images/JLL_Logo_Anniv.svg');
      background-position: center;
    }
    img { opacity: 0; }
  });
  .resp(@mobilemax, {
    a {
      background-image: url('../images/JLL_Logo_Anniv.svg');
      background-position: center;
    }
    img { opacity: 0; }
  });
}