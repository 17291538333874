
// Dropdown filter
// ================================

/* --- [ Mixin ] --------------- */

.dropdown-filter(
  @padding: @inline-grid-padding;
  @label: ~'> label';
  @pane: @label + ~'+';
) {
  // See forms.less for variables
  position: relative;
  color: @text-color;

  .select-box(
    @select: not-native;
    @toggle: ~'> label';
    @pane: ~'> .views-widget';
  );

  select {
    border: none;
    background-image: none;
  }

  @{label} {
    display: block;
    margin: 0;
    position: relative;
    line-height: 0;
    > * { line-height: normal; }

    > .text {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .choice-pill {
      .font(@display-font, 'black', .725rem);
      line-height: @base-line-height;
      display: inline-block;
      letter-spacing: .125em;
      text-transform: uppercase;
      margin: .25rem .35rem;
      padding: .05rem .625rem;
      padding-right: 2rem;
      border-radius: 0;
      border: none;
      box-shadow: none;
      background: fade(@brand-mono,60%);
      color: @white;
      .font-smoothing(antialiased);
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      cursor: pointer;

      &:after {
        content: ' ';
        .abs(0,0,0,auto);
        width: 2rem;
        background-image: @close-x !important;
        background-repeat: no-repeat !important;
        background-size: .8125rem auto !important;
        background-position: center !important;
      }
      + .text {
        display: none;
      }
    }
    .toggleSwitch {
      // See: global.js
      .abs(0,0,0,auto);
      .trans(transform);

      .icon();
      .text { .element-invisible(); }

      &.isOpen {
        .transform(scale3d(1,-1,1));
      }
      &.isClosed {
        .transform(scale3d(1,1,1));
      }
    }
  }

  @{pane} {
    margin: 0;
    #ma-toggle.pane();
    .form-item { margin: 0; }
  }

  .form-item {
    margin: 0;
  }
}
