  .commerce-add-to-cart {
    > div > * ~ * {
      margin-top: 20px;
    }

    .quantity-wrapper {
      padding: 20px 30px;
      box-sizing: border-box;
      background-color: @hr-color;
      min-width: 100%;
      width: calc(~"100% + 60px");
      margin: 20px -30px;

      > * {
        display: table-cell;
        vertical-align: middle;
        width: 50%;

        &:nth-child(odd) {
          padding-right: 10px;
        }
        &:nth-child(even) {
          padding-left: 10px;
        }
      }
    }
    .price {
      font-family: @heading-font;
      font-size: 24px;
      color: @brand-secondary;
      width: auto;
    }
    .form-item-quantity {
      position: relative;
      width: 500px;  // A hack to get the thing be full width
      label { .element-invisible; }
    }
    .quantity-buttons {
      position: absolute;
      .abs(0,0,0,auto);
      border: 1px solid transparent; // prevent overlap of input border
      &:after {
        content: ' ';
        height: 1px;
        .abs(50%,0,auto,0);
        background-color: darken(@hr-color, 5%);
      }

      .commerce-quantity-plusminus-link {
        display: block;
        float: none;
        height: 50%;
        width: 30px;

        a {
          margin: 0;
          display: block;
          height: 100%;
          border: none;
          border-radius: 0;
          padding: 0;
          line-height: 1;
          text-indent: -9999px;
          position: relative;
          background-color: @hr-color;

          &:before {
            content: ' ';
            background-repeat: no-repeat;
            background-color: transparent;
            background-size: 10px 10px;
            background-position: center;
            display: block;
            .abs(0,0,0,0);
          }

          &:focus {
            transform: none;
          }
          &:active {
            background-color: @faint-bg;
          }
        }

        &[class*="increase"] a:before {
          background-image: url(../images/arrowup--gray.svg);
        }
        &[class*="decrease"] a:before {
          background-image: url(../images/arrowdown--gray.svg);
        }
      }
    }

    label[for="edit-quantity"] {
      .element-invisible;
    }

  }


  #views-form-commerce-cart-block-default .form-submit {
  // See: /checkout/11
  margin-left: auto;
  margin-right: 16px;
  display: block;
  width: auto;
  padding: 4px 8px 3px;
  position: relative;
  top: -20px;
  font-size: 13.5px;

  &:hover {
    color: @white !important;
  }
}


.cart_contents {
  legend {
    padding: 0;
    background-color: transparent;

    span {
      color: @brand-secondary;
      padding-bottom: 8px;
      border-bottom: 3px solid @hr-color;
      margin-bottom: 30px;
      font-size: 24px;
      line-height: 1.5;
    }
  }
}