/* --- [ Progress Bar (System) ] --------------- */
.progress {
  .bar {
    border: none;
    background-color: @hr-color;
  }
  .filled {
    background-color: @brand-primary;
    height: 5px;
  }
  .percentage {
    .meta;
    float: right;
  }
  .message {
    color: @faint-text-color;
    font-size: @caption-font-size;
    margin-right: 40px;
  }
}
