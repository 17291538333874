.node-type-class {
  .region-sidebar-first-inner {
    .group-credit-info {
      margin-bottom: 2em;
    }
    .field-label {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.02em;
      color: @grey;
      margin-bottom: 0;
      margin-top: 1em;
      &:after {
        content: ":";
        position: relative;
        right: .2em;
      }
    }
    .field-contents {
      font-weight: bold;
      a {
        text-transform: none;
        font-size: 1rem;
      }
    }
  }
  .field-instructor,
  .field-co-instructor,
  .field-call-out {
    h3.field-label,
    .field-contents h3 {
      text-transform: none;
      letter-spacing: normal;
      margin-top: .25em;
    }
    h3.field-label {
      font-size: 2.25rem;
      letter-spacing: 0.02em;
      font-weight: 600;
    }
    .field-contents h3 {
      font-size: 1rem;
    }
  }
  .field-instructor {
    padding-top: 1.35rem;
  }
  .field-co-instructor {
    margin-bottom: 1.35rem;
  }
  .field-call-out {
    padding-top: 1.35rem;
    padding-bottom: 1.35rem;
    border-top: 2px solid #d7d7d7;;
    p {
      font-style: italic;
    }
  }

  .region-sidebar-first,
  .region-sidebar-second {
    @media (max-width: 961px) {
      margin-bottom: 0 !important;
      &:before {
        border-top: none !important;
      }
      .ds-region {
        display: flex;
        flex-wrap: wrap;
        .field-instructor,
        .field-co-instructor,
        .group-credit-info,
        .group-pricing {
          flex-basis: 50%;
          flex-shrink: 0;
          padding-top: 1.35rem;
          margin-bottom: 1.35rem;
          @media (max-width: 541px) {
            flex-basis: 100%;
          }
        }
        .field-co-instructor {
          padding-top: 5.75rem;
        }
        @media (max-width: 541px) {
          .field-instructor {
            margin-bottom: 0;
          }
          .field-co-instructor {
            padding-top: 0;
          }
        }

      }
    }
  }

}