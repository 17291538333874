
// View Modes
// ================================

/* --- [ Mixins ] --------------- */
.for-unpublished-items() {
  &[class*="unpublished"] {
    position: relative;
    &:after {
      content:  ' ';
      pointer-events: none;
      .abs-0();
      background-color: fade(red, 20%);
      mix-blend-mode: multiply;
      visibility: visible;
      height: auto;
      display: block;
    }
  }
}
.card-style (
  @title_el: ~".field-title",
  @meta: ~".group-meta",
  @body: ~".field-body",
  @more_link: ~".field-node-link"
  ) {

  background-color: @white;
  padding: 1.05rem 1.625rem;
  .resp(@mobilemax, { padding: 1.6rem 1.25rem 2.4rem; });

  @{meta} {
    margin-bottom: .2rem;
  }

  .field[class*="signature"] {
    .entity > .field {
      margin-top: 1.9rem;
      margin-bottom: 1.2rem;

      &.field-image-placeholder {
        display: none;
      }
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  @{title_el} {
    color: @black;
    line-height: 1.5;
    margin-bottom: 1.6rem;
    > * { line-height:  inherit; }
    a {
      color: inherit;
      &:hover { text-decoration: underline; }
    }
  }
  @{body} {
    line-height: 1.35;
    ~ * {
      margin-top: 1.3rem;
    }
  }

  @{more_link} {
    .more-link();
  }
}
.card-style-post (
  @title_el: ~".field-title",
  @meta: ~".group-meta",
  @body: ~".field-body",
  @more_link: ~".field-node-link"
  ) {
  .card-style(@title_el, @meta, @body, @more_link);
  padding: 2.1rem 2rem;
  .resp(@mobilemax, { padding: 1.6rem 1.25rem 2.4rem; });

  @{title_el} {
    color: @black;
    line-height: 1.65;
    margin-bottom: 1.5rem;
    * { line-height:  inherit; }
    a {
      color: inherit;
      &:hover { text-decoration: underline; }
    }
  }

  @{body} {
    color: @medium-text-color;
    line-height: 1.35;
    font-size: 1rem;
    ~ * {
      margin-top: 1.3rem;
    }
  }

}

.list-style(@title_el: ~".field-title", @body: ~".field-body", @meta: ~".group-meta") {
  @{title_el} {
    &, & * {
      .h4();
      margin-bottom: .55rem;
      font-size: @h3-size;
      a {
        display: block;
          &, * { color: @link-hover-color; }
      }
    }
    > * { font: inherit; }
  }
  .field[class*="signature"] {
    img {
      width: 100%;
      height: auto;
    }
    ~ * {
      margin-top: .85rem;
    }
  }
  @{body} {
    font-size: @h4-size;
    line-height: @base-line-height;
    ~ * {
      margin-top: .85rem;
    }
  }
  @{meta} {
    .meta();
    ~ * {
      margin-top: .85rem;
      &.field[class*="signature"] {
        margin-top: 0;
        .entity > * {
          margin-top: .85rem;
        }
      }
    }
  }
}


/* --- [ Apply Mixins ] --------------- */
[class*='view-mode'] { .for-unpublished-items() !important; }
.view-mode {
  &-teaser {

    &.node-blog-post {
      // See: / (From the Blog)
      .front & {
        .card-style();
      }
      // See: /blog
      .not-front & {
        .card-style-post();
      }
    }

    &.node-blog-post {

    }

    // See: /research/databases
    &.node-database-detail {
      .list-style();
    }

    // See: /research/databases
    &.node-guide {
      .list-style();
    }
  }

  &-full {

    // See: /about/directions
    &.node-page {
      max-width: 630px;
      .resp(@tabletmax, { margin: 0 auto; });
    }

    // See: /donate
    &.node-webform {
      .card-style-post();
      position: relative;

      .messages {
        margin: -2.1rem -2rem;
        margin-bottom: 2rem;
      }

      .fieldset-wrapper {
        font-size: 0;
        > .form-item {
          .__m-top();

          &[class*="conditional"] {
            + .form-item[style*="display"],
            + .form-wrapper[style*="display"] {
              margin-top: -.5rem;
              margin-bottom: 2rem;
            }

            + .form-wrapper[style*="display"] {
              .fieldset-wrapper > .form-item:first-child { margin-top: 0; }
            }
          }
        }
      }

      /* --- [ Donation specific ] --------------- */

      .form-radios[id*="gift-amount"] {
        font-size: 0;
        .__m-top();
        .resp(@tabletmax, {
          margin: 1rem -.5rem 0;
        });
      }
      .form-item[class*="submitted"][class*="gift-amount"] {
        display: inline-block;
        vertical-align: middle;
        margin-right: .5rem;

        .resp(@tabletmax, {
          margin-right: 0;
          width: 50%;
          padding: 0 .5rem;
        });
        input {
          + label {
            .button();
            display: block;
            cursor: pointer;
            &:before { content: none; }
          }
          &:checked + label {
            .button-cta();
            display: block;
            cursor: default;
            &:hover {
              background-color: @brand-secondary;
              border-color: @brand-secondary;
            }
          }
        }
      }
      .form-item[class*="gift-amount"] {

        .__one-fifth-width();
        .resp(@goldilocks, {
          .__two-fifths-width();
        });
        .resp(@mobilemax, {
          .__full-width();
        });
        label {
          width: 100%;
        }
        input {
          float:left;
          width: 85%;

        }
        .field-prefix {
         margin: 8.5px 6px 6px 0;
         font-weight: normal;
         font-size: 0.875rem;
         line-height: 1.6;
         color: #000000;
         float: left;
        }
      }
      .form-item[class*="display-name"] {
        margin-bottom: 2.5rem;
      }
      .form-item[class*="donor-information"] {
        &[class*="first-name"],
        &[class*="last-name"],
        &[class*="email-address"],
        &[class*="street-address"],
        &[class*="city"],
        &[class*="state"],
        &[class*="phone"] {
          .__half-width();
          .resp(@tabletmax, {
            .__three-fifths-width();
          });
          .resp(@mobilemax, {
            .__full-width();
          });
        }
        &[class*="middle-initial"],
        &[class*="suffix"],
        &[class*="zip"] {
          .__one-fifth-width();
          .resp(@goldilocks, {
            .__two-fifths-width();
          });
          .resp(@mobilemax, {
            .__full-width();
          });
        }

        &[class*="email-address"],
        &[class*="city"] {
          display: block;
        }

        &[class*="first-name"],
        &[class*="last-name"],
        &[class*="email-address"],
        &[class*="street-address"],
        &[class*="city"],
        &[class*="state"],
        &[class*="phone"] {
          .resp-min(@mobilemax + 1, {
            .__p-right();
          });
        }

        &[class*="middle-initial"],
        &[class*="suffix"],
        &[class*="zip"] {
          .resp-min(@mobilemax + 1, {
            .__p-left();
          });
        }
      }

      #edit-submitted[style*="display"][style*="none"] ~ .form-actions {
        margin-top: -.5rem;
        padding-top: 0;
      }
    }

    // See: /ex-research
    &.node-landing-page {
      .resp-min(@tabletmax + 1, {
        &,
        .block > .content > &:first-child {
          // Pull the main content up
          // so that the top of the heading lines up
          // width the right sidebar heading
          margin-top: -.475rem;
        }
      });
    }

    // See: /research/databases/court-records-briefs-index
    &.node-database-detail {
      max-width: 630px;
      // margin-left: auto;
      // margin-right: 0;
      // .resp(@tabletmax, {
      //   margin-left: 0;
      //   margin-right: auto;
      // });
      // // See: JLLUAT-273
    }

    // See: /research/guides
    &.node-listing-page {
      position: relative;

      > .field-body {
        font-size: @h4-size;
        line-height: @fine-title-line-height;
        color: @medium-text-color;
        margin-bottom: 2.25rem;
      }
    }

    // See: /research/guides/federal-taxation/caselaw
    &.node-guide-tab {
      > .ds-header {
        .field-title {
          &, & > * { .h2-aside(); }
        }
      }
      > .ds-media {
        > .ds-media-text {
          width: 66.511%;
          .resp(@mobilemax, {
            width: 100%;
          });
        }
        > .ds-media-aside {
          width: 33.489%;
          padding-left: 2.75rem;

          .resp(@mobilemax, {
            width: 100%;
            padding-left: 0;
            margin-top: 2.8rem;
          });

          > * {
            margin-top:  1.4rem;
            &:first-child {
              margin-top: -.3rem;

              .resp(@mobilemax, {
                margin-top: 0;
              });
            }
          }
        }
      }
    }

    &.node-guide-section {
      .list-style();
      border-top: 1px solid @hr-color;
      padding-top: 1.4rem;
      margin-top: 1.4rem;

      .field-title {
        &, & * {
          .h3();
          margin-bottom: 1.25rem;
        }
      }
      .resp(@mobilemax, {
        margin-bottom: 1.4rem;
        &:last-child {
          margin-bottom: 0;
        }
      });

      > .ds-media {
        > .ds-media-text {
          width: 66.511%;

          .resp(@mobilemax, {
            width: 100%;
          });
        }
        > .ds-media-aside {
          width: 33.489%;
          padding-left: 2.75rem;

          .resp(@mobilemax, {
            width: 100%;
            padding-left: 0;
            margin-top: 2.8rem;
          });

          > * {
            margin-top:  1.4rem;
            &:first-child {
              margin-top: -.3rem;

              .resp(@mobilemax, {
                margin-top: 0;
              });
            }

            // See: /research/guides/federal-taxation/caselaw
            .field-collection-item-field-see-also {
              > * {
                margin-top: 2.75rem;
                &:first-child { margin-top: 0; }
              }
            }
          }
        }
      }
    }

    // See: /research/guides/federal-taxation/caselaw
    &.field-collection-item-field-see-also {
      > * {
        margin-top: 2.75rem;
        &:first-child { margin-top: 0; }

        .resp(@tabletmax, {
          margin-top: 1.25rem;
          &:first-child { margin-top: 0; }
        });
      }
    }

    // See: /research/guides
    &.field-collection-item-field-contact-details {
      color: @faint-text-color;

      .resp(@tabletmax, {
        text-align: center;
      });

      > * {
        margin-top: 1.45rem;

        .resp(@tabletmax, { margin-top: 1.25rem; });
        &:first-child { margin-top: 0; }
      }
      .field-department {
        .h4-aside();
         color: @faint-text-color;
        + * { margin-top: 0; }
      }
    }
  }

  &-default {
    // See: /
    &.bean-visitor-info {
      > * ~ * {
        margin-top: 1.7rem;

        .resp(@mobilemax, {
          margin-top: 1.6rem;
        });
      }

      > .field-links {
        border-top: 2px solid @hr-color;
        padding-top: 1.75rem;
        margin-top: 2.45rem;
      }

      .field-links {
        a {
          .remove-icon-inline-after();
          .action-link();

          ~ a {
            margin-top: 1.1rem;
          }
        }
      }

      .resp-range(@baby_bear + 1, @tabletmax, {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        &:before, &:after { display:  none; }

        > * {
          flex: 0 1 auto;
          margin: 0;

          &:nth-child(2),
          &.group-tel {
            order: 3;
          }
          &:nth-child(3),
          &.field-hours {
            order: 2;
          }
          &:last-child,
          &.field-links {
            order: 100;
            flex-basis: 100%;
            margin-top: 1.8rem;
            margin-bottom: 0;
          }
        }

        > .field-links {
          border: none;
          padding-top: 0;
        }

        .field-links {
          text-align: center;
          a {
            .remove-icon-inline-after();
            .action-link();
            display: inline-block;
            ~ a {
              margin: 0;
              .pipe-before(@medium-text-color);
            }
          }
        }
      });
    }
    // See: /research/guides
    &.bean-contact-us {
      .field-contact-details {
        margin-top: .5rem;

        > * ~ * {
          margin-top: 1.7rem;

          .resp(@mobilemax, {
            margin-top: 1.6rem;
          });
        }

        .resp-range(@mobilemax + 1, @tabletmax, {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
          &:before, &:after { display:  none; }

          > * {
            flex: 0 1 auto;
            margin: 0;
            margin-bottom: 1.5rem;
          }

          > .field-links {
            border: none;
            padding-top: 0;
          }

          .field-links {
            text-align: center;
            a {
              .remove-icon-inline-after();
              .action-link();
              display: inline-block;
              ~ a {
                margin: 0;
                .pipe-before(@medium-text-color);
              }
            }
          }
        });
      }
    }
  }
}
