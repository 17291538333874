
.flexslider {
  .unbullet();
  margin: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 2.5rem;

  img {
    width: 100%;
    max-width: auto;
  }


  &.default {
    @captionHeight: 2.25rem;
    @ctrlWidth: 5.25rem;

    .slides {
      > li {
        display: none !important;

        &.flex-active-slide {
          display: block !important;
        }
      }
    }

    .slide--inner {
      position: relative;
    }
    .slide--tray {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: @captionHeight;
      background-color: fade(@black, 60%);
      display: table;
      width: 100%;

      .resp(@mobilemax, {
        position: relative;
      });

      .hasNoCaptions&,
      .hasSoleSlide&, {
        display: none;
      }
      > * {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        vertical-align: middle;
        .abs-0();
        padding: 0 3.75rem;
        &.slide--counter {
          justify-content: center;
        }
        &.flex-direction-nav {
          min-width: @ctrlWidth * 2;
          padding: 0;
          > li {
            position: absolute;
            top: 0;
            bottom: 0;
            &:nth-child(odd) { left: 0; }
            &:nth-child(even) { right: 0; }
          }
        }
      }
    }

    .flex-caption {
      padding: 0.85rem 0 1.6rem;
      border-bottom: 2px solid @hr-color;

      &.flex-caption-empty {
        display: none;
      }
    }

    .flex-direction-nav {
      margin: 0 !important;
      font-size: 0;

      .hasSoleSlide& {
        display: none;
      }

      > li {
        display: inline-block;
        font-size: 1rem;
      }

      a {
        display: block;
        opacity: 1;
        visibility: visible;
        width: @ctrlWidth;
        height: @captionHeight;
        line-height: 1;
        color: transparent;
        text-shadow: none;
        box-shadow: none;
        top: auto;
        bottom: 0;
        margin: 0;
        position: relative;

        &:before,
        &:after {
          display: block;

          height: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          height: auto;
          font-family: inherit;
          font-size: @captionHeight;
          line-height: 1;
          text-align: center;
          transform: translateY(-50%);
          background-repeat: no-repeat;
          background-position: center;
          background-size: .75rem;
          .trans;
        }
        &:before { opacity: 1; }
        &:after { opacity: 0; }
        &:hover {
          &:before { opacity: 0; }
          &:after { opacity: 1; }
        }
      }
      .flex-prev {
        left: 0;

        &:before {
          font-family: inherit;
          content: '\2039';
          color: transparent;
          text-shadow: none;
          background-image: @chevron-left--white;
        }
        &:after {
          font-family: inherit;
          content: '\2039';
          color: transparent;
          text-shadow: none;
          background-image: @chevron-left--aqua;
        }
      }
      .flex-next {
        right: 0;

        &:before {
          font-family: inherit;
          content: '\203A';
          color: transparent;
          text-shadow: none;
          background-image: @chevron-right--white;
        }
        &:after {
          font-family: inherit;
          content: '\2039';
          color: transparent;
          text-shadow: none;
          background-image: @chevron-right--aqua;
        }
      }
    }

    .flex-control-nav {
      display: none;
      margin: 0;
      position: static;
    }

    .truncatorEnabled& {
      .flex-caption {
        background: fade(@black, 80%);
        text-shadow: 0 0 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        box-sizing: border-box;
        padding: 1rem 1.125rem .75rem;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &.flex-caption-empty {
          opacity: .2;
        }

        &.hasTruncToggle {
          padding: 1rem (12/@base-font-size)+@ctrlWidth*2 .75rem (12/@base-font-size)+@ctrlWidth;
          height: @captionHeight;

          .hasSoleSlide& {
            padding: 1rem (12/@base-font-size)+@ctrlWidth .75rem .75rem
          }

          &:after {
            content: none;
            position: absolute;
            display: block;
            background-color: transparent;
            left: 0;
            right: 0;
            bottom: 0;
            height: @captionHeight;
            z-index: 0;
          }
        }
      }

      .flex-direction-nav {
        position: static;
        float: none;
        display: block;

        > li { display: inline; }

        a { position: absolute; }
      }
    }

    .toggleCaptionTruncation {
      position: absolute;
      bottom: 0;
      right: @ctrlWidth;
      z-index: 1;
      width: @ctrlWidth;
      height: @captionHeight;
      padding: 0;
      border: none;
      border-radius: 0;
      background-color: transparent;
      background-image: @triple-dots !important;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 60%;
      color: @white !important;
      text-indent: -9999px;
      box-shadow: none;
      .trans(background-color);

      .hasSoleSlide& {
        right: 0;

        &.captionEmpty {
          display: none;
        }
      }

      &:hover {
        opacity: .5;
      }

      &:hover,
      &:focus {
        transform: none;
        box-shadow: none;
      }


      &.truncated {
        background-image: @triple-dots--white !important;
      }
    }

    .flex-caption-empty .toggleCaptionTruncation {
      display:  none;
    }

    &.hasFullCaption .flex-caption.hasTruncToggle {
      display: block;
      white-space: normal;
      padding: 2.25rem 1.125rem (12/@base-font-size)+@captionHeight;
      height: auto;

      &.flex-caption-empty {
        padding: 1rem 1.125rem .75rem;
      }

      &:after {
        content: ' ';
      }
    }

  }

}

