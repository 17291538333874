.altCloseButton {
  display: none;
}

.resp(@mobilemax, {
  body {
    &[class*="mainMenuPane"] {
      #page {
        overflow: visible;
        .trans;
      }
      .region-menu {
        position: static;
      }
    }
    &.mainMenuPane {
      &isClosed {
        #page {
          // transform: translate3d(0, 0, 0); // position fixed is prevented by transforms See: http://stackoverflow.com/questions/16879737/position-fixed-is-not-working
          transition: transform 200ms ease-out 0ms;
        }
      }
      &isOpen {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        position: relative;

        #page {
          transform: translate3d(82%, 0, 0);
          transition: transform 200ms cubic-bezier(0.68, 0.21, 0.35, 0.77) 0ms;
          &:before {
            content: ' ';
            .abs-0();
            background-color: fade(@black, 50%);
            z-index: 2;
            display: block;
          }
        }

        .mainMenuToggleProxy {
          .abs(0,0);
          width: 40px;
          height: 40px;
          z-index: 40;
        }

        .mainMenuPane {
          position: absolute;
          top: 0;
          bottom: 0;
          left: auto;
          right: 100%;
          width: 82%;
          height: 100vh !important;
          padding: 14px 2rem 40px;
          overflow: hidden;
          overflow-y: auto;
          -webkit-overflow-scrolling: touch;
          background-color: @brand-primary;
          color: @white;
          z-index: 30;

          * { color: inherit; }
          .altCloseWrapper {
            position: relative;
          }
          .altCloseButton {
            display: block;
            .abs(2px, -1px, 0, auto);
            width: 20px;
            height: 20px;
            background-color: transparent;

            &:before, &:after {
              content: ' ';
              height: 3px;
              width: 100%;
              background-color: @white;
              transform-origin: center center;
              position: absolute;
              top: 50%;
              left: 50%;
            }
            &:before {
              .t-y(-50%);
              .t-x(-50%);
              .transform(rotate(45deg));
            }
            &:after {
              .t-y(-50%);
              .t-x(-50%);
              .transform(rotate(-45deg));
            }
            .text {
              white-space: nowrap;
              text-indent: -9999px;
              display: block;
              .abs(-1rem,-1rem,-1rem,-1rem);
            }
          }
        }
      }
    }

    &.mainSearchPane {
      &isClosed {
      }
      &isOpen {
        height: 100vh;
        overflow: hidden;
        #page {
          &:before {
            content: ' ';
            .abs-0();
            background-color: fade(@black, 50%);
            z-index: 10;
            display: block;
          }
        }

        .region-menu {
          position: relative;
        }
      }
    }

  }
});

.resp(@mobilemax, {
  body.mainMenuPaneisOpen {
    .mainMenuPane {
      width: 82%;
    }
    #page {
      transform: translate3d(82%, 0, 0);
    }
  }
});