.container-12 {
  width: 100%;
  letter-spacing: -1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > * { letter-spacing: normal; }

  .grid-1,
  .grid-2,
  .grid-3,
  .grid-4,
  .grid-5,
  .grid-6,
  .grid-7,
  .grid-8,
  .grid-9,
  .grid-10,
  .grid-11,
  .grid-12 {
    float: none;
    display: inline-block;
    vertical-align: top;
    display: flex;

    > * {
      //flex: 1 0 auto;
      width: 100%;
      flex-basis: 100%;
      //min-width: 100%; // .region-inner should be constrainted to 1400px
    }

    &#messages {
      display: block;
      width: 100%;
      flex-basis: 100%;
      min-width: 100%;

      > * {
        display: block;
      }

    }
  }
}

.container-12 .grid-12,
.container-12 .grid-8 {
  width: 100%;
  flex-basis: 100%;
  min-width: 100%;
  margin: 0 auto;
}

.container-12 .grid-3 {
  width: 23.5%;
  flex-basis: 23.5%;
  margin: 0;
  box-sizing: border-box;
  min-width: 250px;

  &.region-sidebar-first {
    padding-right: 2.75rem;
    padding-left: 0;

    .page-blog & {
      .resp(@grizzly, {
        padding-right: 0;
      });
    }

    .resp(@tabletmax, {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 42px;

      .gjs-header-tray & {
        margin-bottom: 1.675rem;
      }
    });
  }

  &.region-sidebar-second {
    padding-left: 2.75rem;
    padding-right: 0;
    .right-sidebar & {
      width: 26%;
      flex-basis: 26%;
      min-width: 26%;
      .front& {
        width: 30.5%;
        flex-basis: 30.5%;
        min-width: 30.5%;
        padding-left: 0;

        .resp(@tabletmax, {
          width: 100%;
          flex-basis: 100%;
          min-width: 100%;
          padding-right: 0;
        });
      }
    }

    .resp(@tabletmax, {
      &, .right-sidebar & {
        margin-top: 2.75rem;
        padding: 0;
        width: 100%;
        flex-basis: 100%;
        min-width: 100%;
        position: relative;
        &:before {
          // See: /research/databases
          content: ' ';
          .abs(0,-1.25rem,auto, -1.25rem);
          border-top: 2px solid @hr-color;
        }

      }
    });

    .resp(@mobilemax, {
      &, .right-sidebar & {
        padding: 0;
        margin-top: 2.75rem;

        &:before {
          content: ' ';
          .abs(0,-.75rem,auto, -.75rem);
          border-top: 2px solid @hr-color;
        }
      }
    });

    .two-sidebars & {
      width: 22.66%;
      flex-basis: 22.66%;
      min-width: 22.66%;
      padding: 0;

      .resp(@tabletmax, {
        width: 100%;
        flex-basis: 100%;
        min-width: 100%;
        margin-top: 42px;
        padding: 0;
      });
    }
  }

  .resp(@tabletmax, {
    width: 100%;
    flex-basis: 100%;
    min-width: 100%;
  });
}

.container-12 .grid-6 {
  width: 76.5%;
  flex-basis: 76.5%;
  min-width: 76.5%;
  flex: 1 0 0%;
  margin: 0;
  box-sizing: border-box;

  .resp(@tabletmax, {
    width: 100%;
    flex-basis: 100%;
    min-width: 100%;
  });
}

.container-12 .grid-9 {
  width: 76.5%;
  flex: 1 0 0%;
  margin: 0;
  box-sizing: border-box;
  .right-sidebar & {
    width: 68.5%;
    flex-basis: 68.5%;
    min-width: 68.5%;
   .front& {
      width: 63.9%;
      flex-basis: 63.9%;
      min-width: 63.9%;
      padding-right: 2.75rem;

      .resp(@tabletmax, {
        width: 100%;
        min-width: 100%;
        padding-right: 0;
        flex-basis: 100%;
      });
    }
  }

  .resp(@tabletmax, {
    &, .right-sidebar & {
      width: 100%;
      padding: 0;
      flex-basis: 100%;
      min-width: 100%;
    }
  });
}

.two-sidebars {
  .container-12 {
    .grid-9,
    .grid-6 {
      width: 45.5%;
      flex-basis: 45.5%;
      min-width: 45.5%;
      padding-left: 0;
      padding-right: 2.75rem;

      .resp(@tabletmax, {
        width: 100%;
        min-width: 100%;
        padding-right: 0;
        flex-basis: 100%;
      });
    }
  }
}

.container-12 {
  .gjs-region.gjs-region--content-right {
    display: inline-block;
    width: 76.5%;
    flex-basis: 76.5%;
    min-width: 76.5%;
    .resp(@tabletmax, {
      width: 100%;
      flex-basis: 100%;
      min-width: 100%;
    });

    .gjs-region__header-tray {
      width: 100%;
      flex-basis: 100%;
      min-width: 100%;
    }
    .grid-9 {
      width: 100%;
      flex-basis: 100%;
      min-width: 100%;
    }
    .grid-6 {
      width: 76.5%;
      flex-basis: 76.5%;
      min-width: 76.5%;
      .resp(@baby_bear, {
        width: 100%;
        flex-basis: 100%;
        min-width: 100%;
      });
    }
    .grid-3 {
      width: 23.5%;
      flex-basis: 23.5%;
      min-width: 23.5%;
      .resp(@baby_bear, {
        width: 100%;
        flex-basis: 100%;
        min-width: 100%;
      });
    }
  }
}




.context-users,
.node-type-webform,
.page-membership,
.page-user,
.page-user-membership,
.page-contact,
.page-contact-request,
.page-user-profile,
.page-user-change-password {
  &:not(.page-user-donations) {
    .right-sidebar& .grid-9 {
      width: 70%;
      flex: 1 0 auto;
      margin: 0;
      box-sizing: border-box;
      .resp(@tabletmax, {
        width: 100%;
        padding: 0;
        flex-basis: 100%;
        min-width: 100%;
      });
    }
    .grid-3.region-sidebar-second {
      width: 30%;
      flex-basis: 30%;
      min-width: 30%;
      flex: 1 0 auto;

      .resp(@tabletmax, {
        width: 100%;
        flex-basis: 100%;
        min-width: 100%;
        &:before { content: none; }
      });
      p, div {

        label {
          font-size: @base-font-size;
        }
      }
    }
  }
}