.block-masquerade {
  padding: 2em;
  margin-top: 50px;
  background-color: @viewport-bg-color;
  display: inline-block;
  width: auto;
  max-width: 100%;

  #quick_switch_links {
    margin-top: 1em;
    border-top: 1px solid @hr-color;
    padding-top: 1em;
    .item-list ul {
      .unbullet;
      li {
        display: inline-block;
        margin: .5em;
      }
      margin: -.5em;
    }
  }
}