// Views
// ================================

/* --- [ Mixins ] --------------- */

.two-cols--left-aside() {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  > .view-filters {
    width: 31.8%;
    padding-right: 2.75rem;
    min-width: 250px;
  }
  > .view-content {
    width: 68.2%;
    margin-top: -.375rem;
    animation: fadein 1s;
  }
  .resp(@tabletmax, {
    flex-wrap: wrap;
    > .view-filters,
    > .view-content {
      width: 100%;
      padding: 0;
    }

    > .view-content {
      margin-top: 2.225rem;
    }
  });
  .resp(@mobilemax, {
     > .view-content {
      margin-top: 1.425rem;
    }
  });
}

.views-rows-ruled(@row_el: ~'.views-row', @padding:1.4rem, @margin:@padding) {
  ~ @{row_el} {
    border-top: 1px solid @hr-color;
    padding-top: @padding;
    margin-top: @margin;
  }
}



/* --- [ Blog ] --------------- */

.view-blog-posts {
  // See: /
  &.view-display-id-blog_homepage {

  }

  // See: /research/databases/example-database
  &.view-display-id-blog_by_database {
    > .view-content {
      color: @medium-text-color;
      .aside-list(~".views-row");

      .views-field-title,
      .views-field-created {
        display: inline;

        a {
          display: inline;
          margin-right: .4rem;
        }
      }
      .views-field-created {
        position: relative;
        top: 1px;
        .bullet-before(.4rem);
      }
    }
  }


  &.view-display-id-blog, // See: /blog
  &.view-blog-posts[class*="-page"] // See: /blog/user/9
  {
    margin-left: -.875rem;
    margin-right: -.875rem;
    .resp-min(@tabletmax + 1, {
      margin-right: -.7rem;
      margin-left: .8rem;
    });
    .view-content.masonry-processed {
      .unbullet();
      position: relative;
      margin-bottom: -1.75rem;

      > .masonry-item {
        padding: 0 .875rem 1.75rem;
        width: 50%;
        .resp(@goldilocks, { width: 100%; });
      }
    }
  }
}


// Landings

.view-menu {
  // See: /ex-research  (landing page)
  &.view-display-id-landing_page_eva {
    > .view-content > .view-grouping {
      //.views-rows-ruled(~".view-grouping");
      ~ .view-grouping {
        padding-top: 1.5rem;
      }

      > .view-grouping-header {
        > h3 {
          .block-title-underlined();
          .h2-aside();
          .resp(@tabletmax, {
            .h2-aside();
          });
          .resp(@mobilemax, {
            .h2--small;
            // MAKE THIS H2 SMALL !!!!!!
            font-size: @h2-small-size;
            line-height: @h2-small-line-height;
          });
        }
      }

      > .view-grouping-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        // See: /cle (About)
        // If not second level grouped
        > .views-row {
          flex: 0 1 auto;
          padding-right: 60px;
          width: 50%;
          &.menu-link-1366 {
            .link;
          }

          .resp(@baby_bear, {
            flex: 1 1 100%;
            width: 100%;
            padding-right: 0;
          });

          .h4();
          padding-bottom: 1.5rem
        }

        .group-header {
            flex: 1 0 100%;
          ~ * {
            margin-top: 1.5rem;
          }
          h4 {
            font-weight: normal;
            font-size: @large-copy-size;
            line-height: @large-copy-line-height;
          }
        }
        .group-content {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;

          .views-row[class*="menu-link"] {
            flex: 0 1 auto;
            padding-right: 60px;
            width: 50%;

            .resp(@baby_bear, {
              flex: 1 1 100%;
              width: 100%;
              padding-right: 0;
            });

            .h4();
            padding-bottom: 1.5rem
          }
        }
      }
    }
  }
}

/* --- [ Database ] --------------- */

.view-database-details {
  // See: /research/databases
  &.view-display-id-database_details_all {
    .two-cols--left-aside();
    > .view-content > .views-row {
      .views-rows-ruled();
    }
  }

  // See: /search/google/education
  &.view-display-id-database_detail_featured {
    > .view-content {
      color: @medium-text-color;
      .aside-list(~".views-row");
    }
  }

  .node-database-detail {
    display: flex;
    justify-content: space-between;
    flex-basis: 100%;
    width: 100%;
    min-width: 100%;

    .resp(@mobilemax, {
      flex-wrap: wrap;
    });

    &:before,
    &:after {
      display: none;
    }
    .ds-first {
      width: 81.8%;
      padding-right: 1.6875rem;

      .resp(@mobilemax, {
        width: 100%;
        padding: 0;
      });
    }
    .ds-second {
      margin: auto 0 auto 1em;
      padding: 0.45rem;

      .resp(@mobilemax, {
        width: 100%;
        margin-top: .625rem;
      });
    }
  }

}


/* --- [ Research Guides ] --------------- */

.view-research-guides {
  // See: /research/guides
  &.view-display-id-guides_all {
    .two-cols--left-aside();
    > .view-content > .views-row {
      .views-rows-ruled();
    }
  }

  // See: /research/guides (sidebar)
  &.view-display-id-guides_popular,
  &.view-display-id-guides_updated {
    > .view-content {
      color: @medium-text-color;
      .aside-list(~".views-row");
    }
  }
}

/* --- [ Research Guides ] --------------- */

.view-recent-databases {

  // See: /user (sidebar)
  &.view-display-id-block {
    > .view-content {
      color: @medium-text-color;
      .aside-list(~".views-row");
    }
  }
}

/* --- [ CLE ] --------------- */

.view-class-sessions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .view-filters {
    flex-basis: ~"calc(31.8% - 2.75rem)";
    margin-right: 2.75rem;
    min-width: 250px;
    flex-shrink: 0;
    height: 100%;
    background-color: @tan;

    @media (max-width: 961px) {
      flex-basis: 100%;

      form {
        &.asidePane {
          padding: 2.375rem 1.2rem 1.3rem;
        }
        & > div {
          width: 100%;
        }
      }
    }

    & > label {
      .h3;
      border-bottom: 2px solid @dove;
      margin: 0 1.35rem;
      padding: 1.2rem 0 .75rem;
      display: block;
      position: relative;

      @media (max-width: 961px) {
        border-bottom: 0px none transparent;
      }

    }

  }

  .views-exposed-form {
    & > .views-exposed-widgets {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0;
      padding: .4rem 1.35rem 1.2rem;
      @media (max-width: 961px) {
        padding: .4rem 0 0rem;
      }
      & > .views-exposed-widget {
        padding: 0;
        label {
          margin: 0 0 .2rem 0;
          color: @grey;
        }
        & > label {
          text-transform: uppercase;
          margin-top: 1.1rem;
          @media (max-width: 961px) {
            margin-top: 0;
          }
        }
        .form-type-bef-checkbox {
          margin-bottom: .6rem;
          margin-top: .5rem;
        }
        &.views-submit-button {
          width: 100%;
          margin-top: 2.1rem;
          input[type="submit"] {
            min-width: 100%;
          }
        }
      }
      #edit-field-credit-type-value-wrapper {
        flex-basis: 100%;
        width: 100%;
        margin-bottom: .4rem;

        @media (max-width: 961px) {
          flex-basis: ~'calc(100% / 3)';
          width: ~'calc(100% / 3)';
        }

        @media (max-width: 601px) {
          flex-basis: 100%;
          width: 100%;
        }

        .option {
          margin: 0;
          padding-left: 1.7rem;
        }
      }
      #edit-field-session-date-value-wrapper,
      #edit-field-session-date-value2-wrapper {
        flex-basis: 50%;
        width: 50%;

        @media (max-width: 961px) {
          flex-basis: ~'calc((100% / 3) - 1px)';
          width: ~'calc((100% / 3) - 1px)';
        }

        @media (max-width: 601px) {
          flex-basis: 50%;
          width: 50%;
        }

        .form-item input {
          width: 100%;
        }
      }

    }
    .views-reset-button {
      display: none;
    }
  }

  .view-header {
    // front page only
    flex-basis: 100%;
    margin-top: 2rem;
  }

  .view-content {
    flex-basis: 68.2%;
    margin-top: -1.95rem;
    @media (max-width: 961px) {
      flex-basis: 100%;
      margin-top: 2.225rem;
      .dropdown-filter;
    }

    .views-row {
      overflow: auto;
      display: flex;
      align-items: center;
      padding: 1.4rem 0;
      border-bottom: 1px solid @dove;
      &-last {
        border-bottom: 0px none;
      }
      /* Show Register button on Mobile */
      flex-wrap: nowrap;
      flex: 0;
      -webkit-flex: 0;
      -ms-flex: 0;
      @media (max-width: 401px) {
        flex-wrap: wrap;
        flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
      }
    }

    .calendar-tile,
    .calendar-tile-range {
      width: 70px;
      display: inline-block;
      height: 85px;
      border: 1px solid @dove;
      text-align: center;
      padding: .25em;
      margin: .5em 1.5em .5em 0;
      flex-shrink: 0;
      .calendar-tile-dow,
      .calendar-tile-month {
        display: block;
        font-weight: 900;
        font-size: .875em;
        text-transform: uppercase;
        color: @grey;
        line-height: 1.2;
      }
      .calendar-tile-daynum {
        font-size: 1.8em;
        line-height: 1;
        font-weight: 900;
        color: @teal;
      }
    }

    .views-field {
      flex-basis: 100%;
      flex: 1;
    }

    .field-title {
      margin-bottom: .55rem;
      font-weight: 600;
      font-size: 1.25rem;
      letter-spacing: .4px;
    }


    .date-display-range {
      font-size: 1.125rem;
      letter-spacing: .02em;
      line-height: 1.4;
      color: black;
    }

    /*.field-credit-type:before {
      content: "•";
      display: inline-block;
      margin: 0 .1em;
      font-weight: normal;
      font-size: .75rem;
    }*/

    .field-credit-type {
      margin-top: .55rem;
    }

    .field-credit-type a,
    .field-credit-type a span {
      color: @grey;
      font-weight: 600;
    }

    .field-add-to-cart-form {
        flex-shrink: 0;
        margin-left: 3rem;
        .commerce-add-to-cart > div > * ~ *,
        .form-submit {
          margin : 0;
        }
        .form-button-disabled,
        .form-button-disabled:hover {
          background-color: @dove;
          border-color: @dove;
        }
/* Show Register button on Mobile */
        flex: 0;
        -webkit-flex: 0;
        -ms-flex: 0;
        box-sizing: inherit;
        @media (max-width: 401px) {
          -webkit-flex: 1 0 200px;
          -ms-flex: 1 0 200px;
          flex: 1 0 200px;
          box-sizing: border-box;
          margin: 15px auto 0;
          text-align: center;
        }
    }
  }

  .pager-bar {
    flex-basis: 68.2%;
    flex-shrink: 0;
    margin-left: auto;
    @media (max-width: 961px) {
      flex-basis: 100%;
    }
  }



  // per-class view
  &.view-display-id-class_sessions_by_class {
    .view-content {
      flex-basis: 100%;
      .views-row {
        background-color: white;
        margin-top: 1em;
        padding: 1em;
        justify-content: space-between;

        .field-session-time,
        .field-commerce-stock {
          flex-basis: 40%;
        }

        .date-display-range {
          font-size: 1rem;
          color: @grey;
        }

        @media (max-width: 801px) {
          flex-flow: column wrap;
          justify-content: flex-start;
          height: 120px;

          .field-session-time,
          .field-commerce-stock,
          .field-add-to-cart-form {
            width: 250px;
            @media (max-width: 651px) {
              width: 100%;
            }
          }
          .field-session-time {
            flex-basis: 100%;
            display: flex;

            @media (max-width: 651px) {
              display: block;
              flex-basis: auto;
            }

            .field-contents {
              margin-top: auto;
              margin-bottom: auto;
            }
          }
          @media (max-width: 651px) {
            height: auto;
            .field-commerce-stock {
              margin-top: 1em;
              margin-bottom: .5em;
            }
          }
        }
      }
    }
  }

  // sidebar tweaks
  &.view-display-id-class_sessions_upcoming_sidebar {
    .view-content {
      flex-basis: 100%;
      .views-row {
        align-items: flex-start;
        padding: .5em 0;
      }
    }
    .calendar-tile {
      zoom: .7;
    }
    .class-sidebar {
      line-height: 1.3;
      font-size: .875rem;
      margin-top: .5em;
      > a {
        font-weight: 600;
        display: block;
        font-size: 1rem;
        margin-bottom: .275rem;
      }
      .meta {
        line-height: 1.3;
        margin-top: 0rem;
        font-size: .75rem;
      }

      .date-display-range {
        font-size: .875rem;
      }

    }

    .more-link {
      margin-top: 0;
      a {
        background-color: transparent;
        color: @teal;
        border-width: 0px;
        border-color: transparent;
        text-decoration: underline;
        padding: 0;
      }
    }

  }

  // homepage tweaks
  &.view-display-id-class_sessions_upcoming_preview {
    .view-content {
      flex-basis: 100%;
    }
  }

}
