html {
  background-color: @contrast-shade-bg;
}
body {

}

.region-page-top {
  background-color: transparent;
  padding: 0 20px;
  color: @white;
  position: static;
  height: auto;

  .resp(@tabletmax, {
    .mainMenuPaneisOpen &,
    .mainSearchPaneisOpen & {
      .abs(0,auto,auto,auto);
      overflow: hidden;
      height: 0;
    }
  });

  a {
    color: @brand-secondary;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  #site-status {
    background-color: transparent;
    padding: 0;
    box-shadow: none;
  }
}


.page-blog,
.page-cart {
  .zone-preface-wrapper,
  .zone-content-wrapper,
  .zone-postscript-wrapper {
    background-color: @faint-bg;
  }
  &.node-type-database-detail {
    .zone-content-wrapper {
      background-color: @white;
    }
  }
}

.page-cart {
  .zone-content {
    & > div {
      background-color: @white;
      padding: 0 2rem;
    }
    .region-content-inner {
      margin-top: 2.1rem;
    }
  }
}

.region-preface-second,
.region-postscript-second {
  background-color: @faint-bg;
}

.section-content {
  background-color: @white;

  .page-contact-request &,
  .page-user &,
  .page-membership &,
  .page-user-login &,
  .node-type-webform & {
    background-color: @faint-bg;
  }
}

.region-user-first {
  background-color: @brand-danger;
  color: @white;
}

.region-postscript-first {
  background-color: @faint-bg;
}

.section-footer {
  background-color: transparent;
  color: @contrast-text-color;
}

.region-footer-first {
  background-color: @contrast-bg;
}
