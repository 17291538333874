
.context-user {
  .zone-content {
    .region-content {
      .fake-block,
      .block-system-main {
        .card-style-post();
        .fieldset-wrapper {
          margin-top: 3rem;
          font-size: 0;
        }
        .form-item {
          label {
            display: inline-block;
            margin-top: 6px;
            width: 100%;
            .h4-aside();
            color: @faint-text-color;
          }
        }
      }
      .page-user-membership&,
      .page-user-donations& {
        .block-system-main {
          background-color: transparent;
          padding: 0;
        }
      }
      .fake-block.form-actions {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        .button {
          .button();
          .button--big();

          &[href*="donate"] {
            .button-cta();
            .button--big();
          }
        }
      }
      .block ~ .block {
        margin-top: 50px;
        &.form-actions {
          margin-top: 0;
          border-top: 3px solid transparent;
          background-color: transparent;
        }
      }
    }
  }
  .zone-content,
  .zone-preface {
    max-width: 1200px;
    min-width: 0;
    margin: 0 auto;
  }

  .region-sidebar-second {
    .region-inner {
      .white-sidebar-blocks();
    }
  }
  table {
    .data-table;
    width: 100%;
    th:first-child,
    td:first-child,
    th:last-child,
    td:last-child, {
      width: 20%;
    }
  }
}

.region-sidebar-second-inner .block.block-account-info {
  h2.block-title {
    font-size: 1rem;
    text-transform: uppercase;
    color: @grey;
  }
}

.page-user-change-password {
  .region-content.grid-12 {
    width: 74%;
    flex-basis: 74%;
    min-width: 74%;
    margin: 0;

    .resp(@tabletmax, {
      width: 100%;
      min-width: 100%;
      padding-right: 0;
      flex-basis: 100%;
    });
  }
}