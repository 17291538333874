
// --- [ fadein ] --------------- //

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

// --- [ fadeinout ] --------------- //

@keyframes fadeinout {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}


// --- [ nodeInserted ] --------------- //
// Detect element insertion
// See: <http://davidwalsh.name/detect-node-insertion>

// Visually Undetectable animation
@keyframes nodeInserted {
  from { opacity: 0.99; }
  to { opacity: 1; }
}