
.page-membership {
  form > div {

    > .form-wrapper {
      &[id*="edit-summary"],
      &[id*="edit-terms"],
      &[id*="edit-info"],
      &[id*="edit-payment"] {
        .card-style-post();
      }
    }
  }
  .region-sidebar-second {
    .region-inner {
      div.block {
        .card-style-post();
        margin: 0;
        padding: 2.1rem 2rem;
      }
    }
  }
  .resp(@tabletmax, {
    .container-12 .grid-3.region.region-sidebar-second {
      padding-top: 2.75rem;
    }
  });

}
// See: /membership/purchase
.page-membership-purchase,
.page-user-membership {
  .region-content.grid-12 {
    width: 74%;
    flex-basis: 74%;
    min-width: 74%;
    margin: 0;

    .resp(@tabletmax, {
      width: 100%;
      min-width: 100%;
      padding-right: 0;
      flex-basis: 100%;
    });
  }
}

.form-item-Account {
  position: relative;
  .request-account-changes-wrap a {
    .button;
    position: absolute;
    top: 2.15rem;
    right: -230px;
    &:hover {
      color: white;
      text-decoration: none;
    }

    .resp(@tabletmax, {
      position: static;
    });
  }
}
